export const Tabs = {
    AllDucks: 0,
    OnLegacyFarming: 1,
    OnSale: 2,
    Artefacts: 3,
    Rebirths: 4,
    BabyDucks: 5,
    Mantles: 6,
    InWars: 7,
    LootBoxes: 8,
    Suits: 9,
    InGames: 10,
    CanBreed: 11,
    GenesisDuckling: 12,
    BredDucklings: 13,
    Turtles: 14,
    TurtlesOnFarming: 15,
    TurtlesCanBreed: 16,
    TurtlesIdle: 17,
    TurtlesOnSale: 18,
    ArGlasses: 19,
    DucksIdle: 20,
    RebirthsTurtles: 21,
    RebirthsDucks: 22,
    RebirthsCanines: 23,
    RebirthsFelines: 24,
    Pete: 25,
    ShakeIt: 26,
    OnVeggFarming: 27,
    OnFarming: 28,
    Canines: 29,
    CaninesOnFarming: 30,
    CaninesCanBreed: 31,
    CaninesIdle: 32,
    CaninesOnSale: 33,
    Felines: 34,
    FelinesOnFarming: 35,
    FelinesCanBreed: 36,
    FelinesIdle: 37,
    FelinesOnSale: 38,
    Mutants: 39,
    MutantsOnFarming: 40,
    MutantsOnSale: 41,
    MutantsIdle: 42,
};

export const TabNames = {
    '': Tabs.AllDucks,
    'all': Tabs.AllDucks,
    'on-legacy-farming': Tabs.OnLegacyFarming,
    'on-vegg-farming': Tabs.OnVeggFarming,
    'on-sale': Tabs.OnSale,
    'items': Tabs.Artefacts,
    'baby-ducks': Tabs.BabyDucks,
    'in-wars': Tabs.InWars,
    'in-games': Tabs.InGames,
    'can-breed': Tabs.CanBreed,
    'genesis-baby-duck': Tabs.GenesisDuckling,
    'non-genesis-baby-duck': Tabs.BredDucklings,
    'turtles': Tabs.Turtles,
    'turtles-on-farming': Tabs.TurtlesOnFarming,
    'turtles-can-breed': Tabs.TurtlesCanBreed,
    'turtles-not-on-farming': Tabs.TurtlesNotOnFarming,
    'tutles-on-sale': Tabs.TurtlesOnSale,
    'ducks-idle': Tabs.DucksIdle,
    'rebirths': Tabs.Rebirths,
    'rebirths-ducks': Tabs.RebirthsDucks,
    'rebirths-turtles': Tabs.RebirthsTurtles,
    'rebirths-canines': Tabs.RebirthsCanines,
    'rebirths-felines': Tabs.RebirthsFelines,
    'pete': Tabs.Pete,
    'shake-it': Tabs.ShakeIt,
    'on-farming': Tabs.OnFarming,
    'mutants': Tabs.Mutants,
    'mutants-on-farming': Tabs.MutantsOnFarming,
    'mutants-on-sale': Tabs.MutantsOnSale,
    'mutants-idle': Tabs.MutantsIdle,
    'canines': Tabs.Canines,
    'canines-on-farming': Tabs.CaninesOnFarming,
    'canines-can-breed': Tabs.CaninesCanBreed,
    'canines-idle': Tabs.CaninesIdle,
    'canines-on-sale': Tabs.CaninesOnSale,
    'felines': Tabs.Felines,
    'felines-on-farming': Tabs.FelinesOnFarming,
    'felines-can-breed': Tabs.FelinesCanBreed,
    'felines-idle': Tabs.FelinesIdle,
    'felines-on-sale': Tabs.FelinesOnSale,
};

export const findTabNameByIndex = {
    [Tabs.AllDucks]: '',
    [Tabs.AllDucks]: 'all',
    [Tabs.OnLegacyFarming]: 'on-legacy-farming',
    [Tabs.OnSale]: 'on-sale',
    [Tabs.Artefacts]: 'items',
    [Tabs.BabyDucks]: 'baby-ducks',
    [Tabs.InWars]: 'in-wars',
    [Tabs.InGames]: 'in-games',
    [Tabs.CanBreed]: 'can-breed',
    [Tabs.GenesisDuckling]: 'genesis-baby-duck',
    [Tabs.BredDucklings]: 'non-genesis-baby-duck',
    [Tabs.Turtles]: 'turtles',
    [Tabs.TurtlesOnFarming]: 'turtles-on-farming',
    [Tabs.TurtlesNotOnFarming]: 'turtles-not-on-farming',
    [Tabs.TurtlesCanBreed]: 'turtles-can-breed',
    [Tabs.TurtlesOnSale]: 'turtles-on-sale',
    [Tabs.DucksIdle]: 'ducks-idle',
    [Tabs.Rebirths]: 'rebirths',
    [Tabs.RebirthsDucks]: 'rebirths-ducks',
    [Tabs.RebirthsTurtles]: 'rebirths-turtles',
    [Tabs.RebirthsCanines]: 'rebirths-canines',
    [Tabs.RebirthsFelines]: 'rebirths-felines',
    [Tabs.Pete]: 'pete',
    [Tabs.ShakeIt]: 'shake-it',
    [Tabs.OnVeggFarming]: 'on-vegg-farming',
    [Tabs.OnFarming]: 'on-farming',
    [Tabs.Mutants]: 'mutants',
    [Tabs.MutantsOnFarming]: 'mutants-on-farming',
    [Tabs.MutantsOnSale]: 'mutants-on-sale',
    [Tabs.Canines]: 'canines',
    [Tabs.CaninesOnFarming]: 'canines-on-farming',
    [Tabs.CaninesCanBreed]: 'canines-can-breed',
    [Tabs.CaninesIdle]: 'canines-idle',
    [Tabs.CaninesOnSale]: 'canines-on-sale',
    [Tabs.Felines]: 'felines',
    [Tabs.FelinesOnFarming]: 'felines-on-farming',
    [Tabs.FelinesCanBreed]: 'felines-can-breed',
    [Tabs.FelinesIdle]: 'felines-idle',
    [Tabs.FelinesOnSale]: 'felines-on-sale',
};

export const PerchColor = {
    Y: 'Y',
    G: 'G',
    R: 'R',
    B: 'B',
};

export const getRarityColor = (rarity) => {
    let rarityColor = 'bg-rarity-low';

    if (rarity === 100) {
        rarityColor = 'bg-rarity-full';
    } else if (rarity > 70) {
        rarityColor = 'bg-rarity-high';
    } else if (rarity >= 30) {
        rarityColor = 'bg-rarity-medium';
    }

    return rarityColor;
};

export const getFPColor = (rarity) => {
    let rarityColor = 'bg-rarity-low';

    if (rarity <= 338) {
        rarityColor = 'bg-rarity-medium';
    } else if (rarity < 1140) {
        rarityColor = 'bg-rarity-high';
    } else {
        rarityColor = 'bg-rarity-full';
    }

    return rarityColor;
};
export const getRarityName = (rarity, t) => {
    let rarityName = t('common.rarity.common');
    if (rarity === 100) {
        rarityName = t('common.rarity.unique');
    } else if (rarity > 70) {
        rarityName = t('common.rarity.very_rare');
    } else if (rarity > 30) {
        rarityName = t('common.rarity.rare');
    }
    return rarityName;
};

export const getOrderByTypes = (t) => ({
    rarity: t('marketplace.order_by.rarity'),
    eggProduction: t('marketplace.order_by.egg_production'),
    name: t('marketplace.order_by.name'),
    generation: t('marketplace.order_by.generation'),
    color: t('marketplace.order_by.color'),
    genes: t('marketplace.order_by.genes'),
    hatchDate: t('marketplace.order_by.hatch_date'),
    farmPower: t('marketplace.order_by.farm_power'),
});

export const allMutantFarmData = (mutants) => {
    const mutantsToClaim = {
        EGG: 0,
        VEGG: 0,
        SPICE: 0,
        FEED: 0,
    };
    let userMutantsFarmingPower = 0;
    let globalMutantsFarmingPower = 0;

    mutants.forEach((mutant) => {
        if (mutant.farmingParams) {
            Object.entries(mutant.farmingParams).forEach(([key, value]) => {
                if (value && typeof value === 'object' && 'toClaim' in value) {
                    mutantsToClaim[key] += value.toClaim;
                }
            });

            globalMutantsFarmingPower = mutant.farmingParams.EGG.global;
        }

        if (mutant.farmingPower) {
            userMutantsFarmingPower += mutant.farmingPower;
        }
    });

    return {
        totalToClaim: mutantsToClaim,
        userMutantsFarmingPower,
        userShareMutantsFarmingPower: ((userMutantsFarmingPower / globalMutantsFarmingPower) * 100).toFixed(2),
        globalMutantsFarmingPower,
    };
};
