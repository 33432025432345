import CommonVeggFarmingService from '$shared/services/vegg-farming/CommonVeggFarmingService';

import helperService from '../helper';

class FrontendVeggFarmingService extends CommonVeggFarmingService {
    protected helperService = helperService;
}

const veggFarmingService = new FrontendVeggFarmingService();

export default veggFarmingService;
