/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAddress } from '$shared/domain/constants';
import { isAddress } from '$shared/domain/guards';
import CommonMutantsService from '$shared/services/mutants/CommonMutantsService';
import { IMutantDetails } from '$shared/types';
import { IMutantDetailsLocked, IMutantDetailsV2 } from '$shared/types/mutants';
import { AnimalConnection } from '$shared/types/animals';
import { urlString } from '$shared/utils';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants/urls';

import assetsService from '../assets';
import auctionService from '../auctions';
import helperService from '../helper';
import mutantsBreeding from '../mutants-breeding';
import mutantsFarmingService from '../mutants-farming';
import scanService from '../scan';

class FrontendMutantService extends CommonMutantsService {
    helperService = helperService;

    mutantsBreedingService = mutantsBreeding;

    mutantsFarmingService = mutantsFarmingService;

    auctionService = auctionService;

    assetsService = assetsService;

    scanService = scanService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    APP_URL = APP_URL;

    API_URL = API_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('MUTANT_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
    };

    fetchUserPageMutants = async (address: string): Promise<IMutantDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<IMutantDetails[]>(
            urlString(`${API_URL}/v2/mutants/address/${address}`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchUserPageMutantsV2 = async (address: addressId): Promise<any> => {
        if (!isAddress(address)) {
            return [];
        }
        try {
            const { data } = await this.helperService.http.get(
                urlString(`${API_URL}/v2/addresses/${address}/mutants/locked`),
                {
                    params: { size: 200_000 },
                },
            );

            const { data: result } = data;

            const allMutantsFromUser: IMutantDetailsLocked[] = result.data.map((mutant: IMutantDetailsV2) => ({
                ...mutant,

                onFarming: mutant.locks?.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                ),
                onSale: mutant.locks?.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            }));

            return allMutantsFromUser;
        } catch (error) {
            console.error('[ERROR][FETCH USER PAGE MUTANTS]', error);
            return [];
        }
    };

    fetchAnimalConnections = async (assetId: animalId): Promise<AnimalConnection[]> => {
        const { data: { data = [] } = {} } = await this.helperService.http.get<{ data: AnimalConnection[] }>(
            `${API_URL}/v2/mutants/${assetId}/connections`,
        );

        return data;
    };
}

export default FrontendMutantService;
