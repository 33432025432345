import { Dispatch, SetStateAction } from 'react';
import { atom, useRecoilState } from 'recoil';

import useFeatureFlag from './useFeatureFlag';

const useEventStyles = async (): Promise<{ [className: string]: string }> => {
    const isHalloweenEnabled = useFeatureFlag('HALLOWEEN_ENABLED');
    const isXmasEnabled = useFeatureFlag('XMAS_ENABLED');

    let styles;
    if (isHalloweenEnabled) {
        styles = await import('$/styles/app/event-layouts/halloweenLayout.scss');
    }
    if (isXmasEnabled) {
        styles = await import('$/styles/app/event-layouts/xmasLayout.scss');
    }
    return styles?.default || {};
};

export const useEventBackgroundHasAnimations = (): boolean => {
    const isHalloweenEnabled = useFeatureFlag('HALLOWEEN_ENABLED');
    const isXmasEnabled = useFeatureFlag('XMAS_ENABLED');

    return isHalloweenEnabled || isXmasEnabled; // add new animation events here
};

export const useHandleToggleFileAnimations = (): {
    animation: boolean;
    setAnimation: Dispatch<SetStateAction<boolean>>;
} => {
    const fileAnimationAtom = atom({
        key: 'file-animation.toggle',
        default: Boolean(!localStorage.getItem('animationIsDisabled')),
    });

    const [animation, setAnimation] = useRecoilState(fileAnimationAtom);

    return { animation, setAnimation };
};

export default useEventStyles;
