import { FC } from 'react';

import useFeatureFlag from '$/hooks/features/useFeatureFlag';
import { useHandleToggleFileAnimations } from '$/hooks/features/useEventStyles';

import XmasSnowRain from './XmasSnowRain';

const CustomAnimations: FC = () => {
    const isXmasEnabled = useFeatureFlag('XMAS_ENABLED');
    const { animation } = useHandleToggleFileAnimations();

    if (!animation) return null;

    if (isXmasEnabled) {
        return <XmasSnowRain />;
    }

    return null;
};

export default CustomAnimations;
