import { useQuery, UseQueryResult } from 'react-query';

import type { HatchingTurtlesParams } from '$shared/types';

import turtlesIncubatorService from '$/services/api/turtles-incubator';

export const hatchingTurtlesQueryKey = (address: addressId): string[] => ['hatching-turtles', address];

const useHatchingTurtles = (address: addressId): UseQueryResult<HatchingTurtlesParams[]> =>
    useQuery(hatchingTurtlesQueryKey(address), () => turtlesIncubatorService.fetchHatchingAnimalParams(address), {
        staleTime: Infinity,
    });

export default useHatchingTurtles;
