import { type ReactNode } from 'react';
import { RecoilRoot } from 'recoil';

interface Props {
    children: ReactNode;
}

const RecoilProvider = ({ children }: Props): JSX.Element => <RecoilRoot>{children}</RecoilRoot>;

export default RecoilProvider;
