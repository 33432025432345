import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

const CARD_WIDTH = 360;
const GAP = 20;

type UseHorizontalScrollable = (
    elementsCount: number,
    isScrollable: boolean,
) => {
    containerRef: RefObject<HTMLDivElement>;
    centeredElement: number;
    scrollToPrevious: () => void;
    scrollToNext: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const useHorizontalScrollable: UseHorizontalScrollable = (elementsCount, isScrollable) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [centeredElement, setCenteredElement] = useState(0);

    useEffect(() => {
        const container = containerRef.current;

        const scrollHandler = () => {
            if (container) {
                setCenteredElement(Math.round(container.scrollLeft / (CARD_WIDTH + GAP)));
            }
        };

        if (isScrollable) {
            container?.addEventListener('scroll', scrollHandler);
        }

        return () => {
            if (isScrollable) {
                container?.removeEventListener('scroll', scrollHandler);
            }
        };
    }, [isScrollable]);

    const setDifferentCenteredElement = useCallback(
        (diff: 'next' | 'previous') => {
            setCenteredElement((oldIndex) => {
                const newIndex = diff === 'next' ? (oldIndex + 1) % elementsCount : (oldIndex - 1) % elementsCount;

                containerRef.current?.scrollTo({
                    behavior: 'smooth',
                    left: (CARD_WIDTH + GAP) * newIndex,
                });

                return newIndex;
            });
        },
        [elementsCount],
    );

    const scrollToPrevious = useCallback(() => {
        setDifferentCenteredElement('previous');
    }, [setDifferentCenteredElement]);

    const scrollToNext = useCallback(() => {
        setDifferentCenteredElement('next');
    }, [setDifferentCenteredElement]);

    return {
        containerRef,
        centeredElement,
        scrollToPrevious,
        scrollToNext,
    };
};
