import { atom } from 'recoil';

export const userSignatureAtom = atom<string | null>({
    key: 'user.signature',
    default: null,
});

export const userSignatureTimestampAtom = atom<integer | null>({
    key: 'user.signature-timestamp',
    default: null,
});
