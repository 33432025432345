import { API_URL } from '$/constants';
import { AuthDataSignService } from '$/services/authentication';

import helperService from '../../services/api/helper';
import authService from '../../services/authentication/instance';

const sendReferrer = async (referrer: string, authType: string, publicKey: string): Promise<void> => {
    const cf = await authService.getIsCollectiveFarm();
    if (cf) {
        return;
    }
    const service = helperService;
    if (!AuthDataSignService.isAuthDataSigned()) {
        await AuthDataSignService.sign(authType, publicKey);
    }
    const userAddress: string = authService.getAccountAddress();
    await service.http.post(
        `${API_URL}/v1/referrer`,
        {
            user: userAddress,
            referrer,
        },
        { params: AuthDataSignService.authParams },
    );
};
export const setReferrerAddress = (referral: string): string => {
    localStorage.setItem('referrer', referral);
    return referral;
};

export const getReferrerAddress = async (): Promise<string> => {
    const referrer = localStorage.getItem('referrer');
    if (referrer) {
        return referrer;
    }

    const service = helperService;
    const userAddress: string = authService.getAccountAddress();
    const output = await service.http.get(`${API_URL}/v1/referrerByReferral/${userAddress}`);
    return output.data?.referrer ? output.data.referrer : '';
};

export const getReferrerAddressAndStore = (authType: string, publicKey: string): string => {
    const referrer = localStorage.getItem('referrer');
    const getReferrer = referrer && referrer !== 'undefined' && referrer !== undefined ? referrer : '';
    if (getReferrer) {
        if (publicKey) {
            void sendReferrer(getReferrer, authType, publicKey);
        }
    }
    return getReferrer;
};
