import { ReactNode } from 'react';

import Tags from '$/components/common/Tags';

import classes from './CardTags.module.css';

interface Props {
    children: ReactNode | ReactNode[];
}

const CardTags = ({ children }: Props): JSX.Element => <Tags className={classes.floating}>{children}</Tags>;

export default CardTags;
