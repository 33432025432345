import classes from './DuckCoverContent.module.scss';

const DuckCoverContent = ({
    genoType,
    image,
    title,
}: {
    genoType?: string;
    image?: string;
    title?: string;
}): JSX.Element => {
    if (genoType === 'WWWWWZOE') {
        return <video src="/static/duck/zoe.mp4" muted autoPlay loop />;
    }

    if (genoType === 'WWWWLUCK') {
        return (
            <div className={classes.pokras}>
                <img src={image} alt={`${title} duck`} />
            </div>
        );
    }

    return <img src={image} alt={`${title} duck`} />;
};

export default DuckCoverContent;
