import { FC, ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import type { IArtefact } from '$shared/types';

import Tag from '$/components/common/Tag';
import Title from '$/components/common/Title';
import { ROUTES } from '$/constants';
import artefactService from '$/services/api/artefact';
import { TDuckCardFooter } from '$/types/duck';

import { Card } from '../Card';
import { getButtonDisabled } from '../Duck/config';
import st from './Artefact.module.scss';

interface IProps {
    isAuth?: boolean;
    artefact: IArtefact | null;
    footerButtonText?: ReactNode;
    withoutLink?: boolean;
    footer?: TDuckCardFooter;
    footerEl?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    num?: number;
    isPageWithNoParams?: boolean;
}

const AbstractArtefact: FC<IProps> = ({
    isAuth,
    artefact,
    withoutLink,
    footer,
    footerEl,
    footerButtonText,
    disabled,
    onClick,
    num,
    isPageWithNoParams,
}) => {
    const { assetId, description, onSale, title, type } = artefact ?? {};
    const { t } = useTranslation();
    const tags = useMemo(() => artefactService.getArtefactTags(artefact), [artefact]);
    let imgName: string | undefined = type;
    const defaultFooter = useMemo(() => {
        if (onSale) {
            return { text: t('artefact.on_sale') };
        }
        if (disabled) {
            return { disabled: getButtonDisabled({ disabled }) };
        }

        return { text: footerButtonText ?? t('artefact.more') };
    }, [onSale, disabled, footerButtonText, t]);

    if (assetId == null || type == null || title == null || imgName == null) {
        return null;
    }

    if (imgName === 'ART-BONE') {
        // eslint-disable-next-line prefer-const
        let imgIndex = Math.abs(
            assetId
                .split('')
                .map((item) => item.charCodeAt(0) - 97)
                .reduce((acc, actual) => acc + actual, 0) % 8,
        );

        imgName = imgIndex === 0 ? imgName : imgName + imgIndex;
    }

    return (
        <Card
            isAuth={isAuth}
            className={st.artefact}
            to={withoutLink ? undefined : generatePath(ROUTES.ITEM, { nftId: assetId })}
            cover={
                <>
                    <img src={artefactService.getItemImage(imgName)} alt={t('artefact.closed_loot_box')} />

                    <div className={st.tagsWrapper}>
                        {tags.map((tag) => (
                            <Tag key={tag} theme="yellow" size="small">
                                <Trans i18nKey={`artefact.${tag}`} />
                            </Tag>
                        ))}
                    </div>
                </>
            }
            footer={footer || defaultFooter}
            footerEl={footerEl}
            onClick={onClick}
            isPageWithNoParams={isPageWithNoParams}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                <span className={st.title}>{t(title)}</span>
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            {description && <p className={st.text}>{t(description)}</p>}
        </Card>
    );
};

export default AbstractArtefact;
