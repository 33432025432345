import { useQuery, UseQueryResult } from 'react-query';

import type { IMarketPrice } from '$shared/types';

import scanService from '$/services/api/scan';

interface IUseDuckMarketPrice {
    canBreed: boolean;
    hasAchievements: boolean;
    genes: string;
    nftId: string;
    rarity: number;
}

const useDuckMarketPrice = (params: IUseDuckMarketPrice): UseQueryResult<IMarketPrice> => {
    const { rarity, canBreed, hasAchievements, genes, nftId } = params;

    return useQuery(
        ['duck-market-price', nftId],
        () => scanService.fetchDuckMarketPrice(rarity, canBreed, hasAchievements, genes),
        { staleTime: Infinity },
    );
};

export default useDuckMarketPrice;
