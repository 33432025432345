import axios from 'axios';

import type { IDucksDetails, IDucksStatsItem, IDuckType, IFarmType, IPlayerState, WarsState } from '$shared/types';

import { API_URL } from '$/constants';

export default class GameApiService {
    static API_PREFIX =
        process.env.GAME_SERVER_URL ||
        (process.env.NODE_ENV === 'development' ? `${API_URL}/game` : 'https://game-server.wavesducks.com/api');

    static fetchAvailableGame(address?: string): Promise<WarsState> {
        const result = address
            ? axios.get(`${this.API_PREFIX}?address=${address}`).then(({ data }) => data)
            : axios.get(`${this.API_PREFIX}`).then(({ data }) => data);
        return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fetchLeaderboardData(gid: string): Promise<any[]> {
        return axios.get(`${this.API_PREFIX}/leaderboard/${gid}`).then(({ data }) => data);
    }

    static fetchFarms(): Promise<IFarmType[]> {
        return axios
            .get<IFarmType[]>(`${this.API_PREFIX}/farms`)
            .then(({ data = [] }) => data.filter((farm) => farm.active === true));
    }

    static chooseFarm(gameId: string, farmContract: string): Promise<void> {
        return axios.patch(`${this.API_PREFIX}/farms/${gameId}/${farmContract}`);
    }

    static fetchAvailableDucks(): Promise<IDuckType[]> {
        return axios.get(`${this.API_PREFIX}/ducks/fighter`).then(({ data }) => data);
    }

    static tryChooseDuck(duckId: string): Promise<void[]> {
        return axios.put(`${this.API_PREFIX}/ducks/fighter/${duckId}`).then(({ data }) => data);
    }

    static tryUnlockDuck(duckId: string): Promise<void> {
        return axios.get(`${this.API_PREFIX}/ducks/unlock/${duckId}`);
    }

    static fetchRentedDuck(address: string): Promise<IDucksDetails> {
        return axios.get(`${this.API_PREFIX}/ducks/rented/${address}`).then(({ data }) => data);
    }

    static fetchDucksCurrentLapStats(): Promise<IDucksStatsItem[]> {
        return axios.get(`${this.API_PREFIX}/ducks/stats`).then(({ data }) => data);
    }

    static fetchDucksOverallStats(): Promise<IDucksStatsItem[]> {
        return axios.get(`${this.API_PREFIX}/ducks/stats/overall`).then(({ data }) => data);
    }

    static fetchPlayerState(): Promise<IPlayerState> {
        return axios.get(`${this.API_PREFIX}/player/state`).then(({ data }) => data);
    }

    static toggleAutoSelectFarm(farmContract: string | null): Promise<void> {
        return axios.patch(`${this.API_PREFIX}/player/autoSelectFarm`, { farmContract }).then(({ data }) => data);
    }

    static fetchSuspendedCacheData(address: string): Promise<number | null> {
        return axios.get(`${this.API_PREFIX}/ducks/suspended/${address}`).then(({ data }) => data);
    }
}
