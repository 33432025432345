/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAddress } from '$shared/domain/constants';
import { isAddress } from '$shared/domain/guards';
import CommonFelinesService from '$shared/services/felines';
import { IAnimalDetails, IAnimalDetailsLocked, IAnimalDetailsV2 } from '$shared/types/animals';
import { urlString } from '$shared/utils';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import assetsService from '../assets';
import auctionService from '../auctions';
import felineBreedingService from '../feline-breeding';
import felinesFarmingService from '../felines-farming/instance';
import felinesIncubatorService from '../felines-incubator';
import helperService from '../helper';
import productionService from '../production';
import scanService from '../scan';

class FrontendFelinesService extends CommonFelinesService {
    breedingService = felineBreedingService;

    farmingService = felinesFarmingService;

    incubatorService = felinesIncubatorService;

    helperService = helperService;

    assetsService = assetsService;

    productionService = productionService;

    auctionService = auctionService;

    scanService = scanService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    APP_URL = APP_URL;

    API_URL = API_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('FELINES_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
    };

    fetchUserPageAnimal = async (address: addressId): Promise<IAnimalDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<IAnimalDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/felines`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchOneAnimalDetailsV2 = async (nftId: string): Promise<IAnimalDetailsLocked> => {
        const [feline] = await Promise.all([this.getOneAnimalDetailsV2(nftId, true)]);
        return feline;
    };

    fetchUserPageFelinesV2 = async (address: addressId): Promise<IAnimalDetailsLocked[]> => {
        if (!isAddress(address)) {
            return [];
        }
        try {
            const { data } = await this.helperService.http.get(
                urlString(`${API_URL}/v2/addresses/${address}/felines/locked`),
                {
                    params: { size: 200_000 },
                },
            );

            const { data: result } = data;

            const allFelineFromUser: IAnimalDetailsLocked[] = result.data.map((feline: IAnimalDetailsV2) => ({
                ...feline,

                onFarming: feline.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                ),
                onSale: feline.locks.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            }));

            return allFelineFromUser;
        } catch (error) {
            console.error('[ERROR][FETCH USER PAGE FELINES]', error);
            return [];
        }
    };
}

export default FrontendFelinesService;
