import { AnyAction } from 'redux';

import type { IDuckType, IFarmType, WarsState } from '$shared/types';

export const LOAD_GAME_DATA = 'LOAD_GAME_DATA';
export const LOAD_GAME_DATA_SUCCESS = 'LOAD_GAME_DATA_SUCCESS';
export const LOAD_GAME_DATA_ERROR = 'LOAD_GAME_DATA_ERROR';
export const LOAD_FARMS = 'LOAD_FARMS';
export const LOAD_FARMS_SUCCESS = 'LOAD_FARMS_SUCCESS';
export const LOAD_FARMS_ERROR = 'LOAD_FARMS_ERROR';
export const LOAD_LEADERBOARD = 'LOAD_LEADERBOARD';
export const LOAD_LEADERBOARD_SUCCESS = 'LOAD_LEADERBOARD_SUCCESS';
export const CHANGE_FARM = 'CHANGE_FARM';
export const INIT_GAMEPLAY = 'INIT_GAMEPLAY';
export const START_MATCHMAKING = 'START_MATCHMAKING';
export const GAMEPLAY_INITIALIZED = 'GAMEPLAY_INITIALIZED';
export const GAMEPLAY_STARTING = 'GAMEPLAY_STARTING';
export const START_GAME = 'START_GAME';
export const GAME_OVER = 'GAME_OVER';
export const CLEANUP_GAMEPLAY = 'CLEANUP_GAMEPLAY';
export const UPDATE_DUCK = 'UPDATE_DUCK';

export const loadGameData = (silent = false): AnyAction => ({ type: LOAD_GAME_DATA, payload: silent });
export const loadGameDataSuccess = (payload: WarsState, silent = false): AnyAction => ({
    type: LOAD_GAME_DATA_SUCCESS,
    payload: { ...payload, silent },
});
export const loadGameDataError = ({ error }: { error?: string }): AnyAction => ({
    type: LOAD_GAME_DATA_ERROR,
    payload: { error },
});
export const loadFarms = (): AnyAction => ({ type: LOAD_FARMS });
export const loadFarmsSuccess = (payload: IFarmType[]): AnyAction => ({ type: LOAD_FARMS_SUCCESS, payload });
export const loadFarmsError = (): AnyAction => ({ type: LOAD_FARMS_ERROR });
export const changeFarm = (farm: IFarmType): AnyAction => ({ type: CHANGE_FARM, payload: farm });
export const initGameplay = (): AnyAction => ({ type: INIT_GAMEPLAY });
export const endGame = (isWin: boolean, winner: string | null, winnerEarned?: number | null): AnyAction => ({
    type: GAME_OVER,
    payload: { isWin, winner, winnerEarned },
});
export const cleanupGameplay = (): AnyAction => ({ type: CLEANUP_GAMEPLAY });

export type WarsActionType =
    | typeof LOAD_GAME_DATA
    | typeof LOAD_GAME_DATA_SUCCESS
    | typeof LOAD_GAME_DATA_ERROR
    | typeof LOAD_LEADERBOARD
    | typeof LOAD_LEADERBOARD_SUCCESS
    | typeof LOAD_FARMS
    | typeof LOAD_FARMS_SUCCESS
    | typeof LOAD_FARMS_ERROR
    | typeof CHANGE_FARM
    | typeof INIT_GAMEPLAY
    | typeof START_MATCHMAKING
    | typeof GAMEPLAY_INITIALIZED
    | typeof GAMEPLAY_STARTING
    | typeof START_GAME
    | typeof GAME_OVER
    | typeof CLEANUP_GAMEPLAY
    | typeof UPDATE_DUCK;

export interface WarsAction {
    type: WarsActionType;
    payload: {
        error: string;
        silent?: boolean;
        winner: string;
        winnerEarned: number;
        isWin: boolean;
        playerId: string;
        opponentId: string;
    } & IFarmType[] &
        IDuckType;
}
