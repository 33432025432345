import cn from 'classnames';
import domAlign from 'dom-align';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ARROW_POSITION_CONFIG, TOOLTIP_POSITION_CONFIG } from './config';
import st from './Tooltip.module.scss';

interface IProps {
    isHideTooltip?: boolean;
    className?: string;
    children: ReactNode;
    containerClassName?: string;
    fitContent?: boolean;
    position: 'top' | 'left' | 'right' | 'bottom';
    theme?: 'blue' | 'silver' | 'golden' | 'platinum';
    target: ReactNode;
}

const Tooltip: FC<IProps> = (props) => {
    const {
        isHideTooltip,
        children,
        className,
        containerClassName,
        fitContent,
        position = 'bottom',
        theme = 'blue',
        target,
    } = props;

    const parentRef = useRef(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);

    const [isShown, setShown] = useState(false);
    const [arrowPosition, setArrowPosition] = useState(position);

    useEffect(() => {
        if (isShown) {
            domAlign(tooltipRef.current, parentRef.current, TOOLTIP_POSITION_CONFIG[position]);
            domAlign(arrowRef.current, parentRef.current, ARROW_POSITION_CONFIG[arrowPosition]);
        }
    }, [isShown, position, arrowPosition]);

    const calculateCoords = () => {
        const { x: tooltipX, y: tooltipY } = tooltipRef.current?.getBoundingClientRect() || {};
        const { x: arrowX, y: arrowY } = arrowRef.current?.getBoundingClientRect() || {};

        if (tooltipX && arrowX && tooltipY && arrowY) {
            if (position === 'left') {
                if (tooltipX > arrowX) {
                    setArrowPosition('right');
                }
            }

            if (position === 'right') {
                if (tooltipX < arrowX) {
                    setArrowPosition('left');
                }
            }

            if (position === 'top') {
                if (tooltipY > arrowY) {
                    setArrowPosition('bottom');
                }
            }

            if (position === 'bottom') {
                if (tooltipY < arrowY) {
                    setArrowPosition('top');
                }
            }
        }
    };

    const handleMouseEnter = () => {
        setShown(true);
        setTimeout(calculateCoords, 0);
    };

    const handleMouseLeave = () => {
        setShown(false);
        setArrowPosition(position);
    };

    const renderTooltip = () => {
        const tooltip = (
            <div
                className={cn(st.tooltipContainer, containerClassName, st[theme], {
                    [st.shown]: isShown,
                    [st.hide]: isHideTooltip,
                })}
                ref={tooltipRef}
            >
                <i className={cn(st.arrow, st[arrowPosition])} ref={arrowRef} />
                {children}
            </div>
        );

        return createPortal(tooltip, document.body);
    };

    return (
        <div
            className={cn(st.tooltip, className, {
                [st.fitContent]: fitContent,
            })}
            ref={parentRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {target}
            {renderTooltip()}
        </div>
    );
};

export default Tooltip;
