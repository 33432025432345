import cn from 'classnames';
import { FC } from 'react';

import st from './CardsContainer.module.scss';

interface IProps {
    className?: string;
    header?: string;
}

const CardsContainer: FC<IProps> = ({ className, children, header }) => (
    <>
        {header && <div className={st.cardsTitle}>{header}</div>}
        <div className={cn(st.cardsContainer, className)}>{children}</div>
    </>
);

export default CardsContainer;
