import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { FEATURE, type FeatureKey } from '$shared/constants';

import isFeatureActiveLocally from '$/browser/features';
import * as featuresService from '$/services/api/features/instance';

type Return = { isLoading: boolean; isEnabled: boolean };

const defaultFeatures = [];

function useFeatureFlag(featureKey: FeatureKey, params: { defaultValue?: boolean; withLoading: true }): Return;
function useFeatureFlag(featureKey: FeatureKey, params: { defaultValue?: boolean }): boolean;
function useFeatureFlag(featureKey: FeatureKey): boolean;
function useFeatureFlag(
    featureKey: FeatureKey,
    { defaultValue, withLoading }: { defaultValue?: boolean; withLoading?: true } = {},
): boolean | Return {
    const { data = defaultFeatures, isLoading } = useQuery(['features'], () => featuresService.getActiveFeatures(), {
        staleTime: 20000,
        refetchOnMount: false,
    });

    return useMemo(() => {
        if (isLoading && defaultValue != null) {
            return defaultValue;
        }
        const isEnabled = isFeatureActiveLocally(featureKey) || data.some((feature) => FEATURE[featureKey] === feature);

        if (withLoading) {
            return {
                isLoading,
                isEnabled,
            };
        }

        return isEnabled;
    }, [data, featureKey, isLoading, withLoading, defaultValue]);
}

export default useFeatureFlag;
