export const ASYNC_STATE = {
    INITIAL: 'INITIAL',

    LOADING: 'LOADING',

    SUCCESS: 'SUCCESS',

    FAILURE: 'FAILURE',
};

type AsyncState = (typeof ASYNC_STATE)[keyof typeof ASYNC_STATE];

export type { AsyncState };
