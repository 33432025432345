import configureAppStore from './configureAppStore';

const store = configureAppStore();

/** @deprecated */
export type AppDispatch = typeof store.dispatch;
/** @deprecated */
export type RootState = ReturnType<typeof store.getState>;

export default store;
