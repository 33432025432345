import { FEATURE, type FeatureKey } from '$shared/constants';

import isFeatureActiveLocally from '$/browser/features';

import getActiveFeatures from './getActiveFeatures';

async function areActive<T extends FeatureKey>(featureKeys: T[]): Promise<Record<T, boolean>> {
    const activeFeatures = await getActiveFeatures();

    return activeFeatures.reduce(
        (obj, feature) => ({
            ...obj,
            [feature]:
                isFeatureActiveLocally(feature) || featureKeys.some((featureKey) => FEATURE[featureKey] === feature),
        }),
        {} as Record<T, boolean>,
    );
}

export default areActive;
