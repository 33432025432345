import { useQuery, type UseQueryResult } from 'react-query';

import type { IArtefact } from '$shared/types';

import artefactService from '$/services/api/artefact';

export const artefactQueryKey = (nftId: assetId): string[] => ['items', nftId];

const useArtefact = (nftId: nftId): UseQueryResult<IArtefact> =>
    useQuery(artefactQueryKey(nftId), () => artefactService.getArtefactDetails(nftId), { staleTime: Infinity });

export default useArtefact;
