import cn from 'classnames';
import { FC } from 'react';

import Spinner from '$/components/common/Spinner';

import st from './PageLoader.module.scss';

interface IProps {
    className?: string;
}

const PageLoader: FC<IProps> = ({ className }) => (
    <div className={cn(st.pageLoader, className)}>
        <Spinner />
    </div>
);

export default PageLoader;
