type CreateObserver = (args: {
    options?: IntersectionObserverInit;
    handleIntersect: IntersectionObserverCallback;
}) => IntersectionObserver;

// eslint-disable-next-line import/prefer-default-export
export const createObserver: CreateObserver = ({ options, handleIntersect }) => {
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        ...options,
    };

    return new IntersectionObserver(handleIntersect, observerOptions);
};
