import cn from 'classnames';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import termsImg from '$/assets/svg/news/terms.svg';
import { NewsCard } from '$/components/Cards';
import Checkbox from '$/components/common/Checkbox';

import st from './NewsModal.module.scss';

interface Props {
    isColumn: boolean;
    isAgree: boolean;
    onClick: () => void;
}

const releasedDateTime = new Date(2022, 5, 4);

const TermsCard = ({ isColumn, isAgree, onClick }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [isChecked, setChecked] = useState(false);

    const handleChecked = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        setChecked(currentTarget.checked);
    }, []);

    return (
        <NewsCard
            isColumn={isColumn}
            className={cn(st.termsCard, { [st.agreed]: isAgree })}
            imgUrl={termsImg}
            releaseDateTime={releasedDateTime}
            title={t('terms.title')}
            text={t('terms.text')}
            link={{
                text: t('terms.button'),
                onClick,
                disabled: !isChecked,
            }}
        >
            <Checkbox id="terms-of-use" className={st.termsCheckbox} checked={isChecked} onChange={handleChecked}>
                <p>
                    {t('terms.checkbox')}
                    &nbsp;
                    <a href="/docs/terms_of_use.pdf" target="_blank" rel="noreferrer noopener">
                        {t('terms.link.text')}
                    </a>
                </p>
            </Checkbox>
        </NewsCard>
    );
};

export default TermsCard;
