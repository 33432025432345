import cn from 'classnames';
import { ChangeEvent, FC } from 'react';

import st from './Checkbox.module.scss';

interface Props {
    checked: boolean;
    disabled?: boolean;
    id?: string;
    name?: string;
    className?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<Props> = ({ id, name, checked, disabled, onChange, className, children }) => (
    <label className={cn(st.checkbox, className)}>
        <input
            checked={checked}
            disabled={disabled}
            type="checkbox"
            className={st.input}
            name={name}
            id={id}
            onChange={onChange}
        />

        <span className={st.text}>{children}</span>
    </label>
);

export default Checkbox;
