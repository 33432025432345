const ARROW_HEIGHT = 6;
const MARGIN = 5;

const OFFSET = ARROW_HEIGHT + MARGIN;

const overflow = {
    adjustX: true,
    adjustY: true,
    alwaysByViewport: true,
};

export const TOOLTIP_POSITION_CONFIG = {
    left: {
        points: ['cr', 'cl'],
        offset: [-OFFSET, 0],
        overflow,
    },
    right: {
        points: ['cl', 'cr'],
        offset: [OFFSET, 0],
        overflow,
    },
    top: {
        points: ['bc', 'tc'],
        offset: [0, -OFFSET],
        overflow,
    },
    bottom: {
        points: ['tc', 'bc'],
        offset: [0, OFFSET],
        overflow,
    },
};

export const ARROW_POSITION_CONFIG = {
    left: {
        points: ['cr', 'cl'],
        offset: [-MARGIN, 0],
    },
    right: {
        points: ['cl', 'cr'],
        offset: [MARGIN, 0],
    },
    top: {
        points: ['bc', 'tc'],
        offset: [0, -MARGIN],
    },
    bottom: {
        points: ['tc', 'bc'],
        offset: [0, MARGIN],
    },
};
