import axios from 'axios';

import { isSignerError, isSignerErrorByCode, isWavesError } from '$shared/domain/exceptions';
import { SIGNER_ERROR_CODE } from '$shared/enums';
import CommonTransactionService from '$shared/services/transaction';
import type { CacheApi, EntityResponse, ITransaction, ITransferTransaction } from '$shared/types';
import { attempt, isDevEnv, urlString } from '$shared/utils';

import { API_URL, DATA_SERVICE_URL, NODE_URL } from '$/constants';
import type { IMassTransfer } from '$/types';

import helperService from '../helper';

export const parseTx = <T extends ITransaction>(tx: T | stringed<T>): T => {
    if (typeof tx !== 'string') {
        return tx;
    }

    try {
        return JSON.parse(tx);
    } catch (e: unknown) {
        console.error(e);

        throw e;
    }
};

/**
 * The class contains methods for transactions
 */
class TransactionService extends CommonTransactionService {
    NODE_URL = NODE_URL;

    API_URL = API_URL;

    helperService = helperService;

    fetchTransaction = async (txId: txId): Promise<CacheApi.Transaction | null> => {
        const { data } = await this.helperService.http.get<EntityResponse<CacheApi.Transaction>>(
            `${this.API_URL}/v1/transactions/${txId}`,
        );

        return data?.entity ?? null;
    };

    fetchTransactionStatus = async (txId: txId): Promise<CacheApi.TransactionStatus> => {
        const { data } = await this.helperService.http.get<CacheApi.TransactionStatus>(
            `${this.API_URL}/v1/transactions/${txId}/status`,
        );

        return data;
    };

    /**
     * Returns a promise to wait for a transaction to complete
     * @param {string} txId transaction id
     * @param {number} [timeout=30000]
     */
    waitForTx = async (txId: string, timeout = 30000): Promise<CacheApi.TransactionStatus | undefined> =>
        attempt(async () => this.fetchTransactionStatus(txId), {
            interAttemptsSleepTime: 1000,
            maxTimeout: timeout,
            isResultSatisfies: (tx) => tx?.status === 'successful',
        });

    /**
     * Get a list of mass-transfer transactions by applying filters
     * @param {string} sender - Address-sender of the transaction; has exclusive relation with senders
     * @param {string} recipient - Search transactions by recipient address
     */
    getMassTransfer = async (
        recipient: string,
        sender = '3PPG6zVNrMKnkqMthk5i2oHWQ5SHs9ertBM',
    ): Promise<IMassTransfer[]> =>
        (
            await axios.get(
                urlString('https://api.wavesplatform.com/v0/transactions/mass-transfer', {
                    sender,
                    recipient,
                    sort: 'desc',
                    limit: 100,
                }),
            )
        ).data.data.map((t) => t.data);

    invokeAuthService = async <Args extends unknown[], T extends ITransaction>(
        service: (...args: Args) => Promise<T | stringed<T>>,
        ...args: Args
    ): Promise<{
        transaction: T;
        waitForTransaction: () => Promise<{ tx: T; fetchDetails: () => Promise<CacheApi.Transaction | null> }>;
    }> => {
        const transaction = parseTx(await service(...args));

        return {
            transaction,
            waitForTransaction: async () => {
                await this.waitForTx(transaction.id);

                return {
                    tx: transaction,
                    fetchDetails: () => this.fetchTransaction(transaction.id),
                };
            },
        };
    };

    handleException = (
        exception: unknown,
        handler: (options: { title?: string; message: string; code: string | number; error: unknown }) => void,
    ): void => {
        if (isSignerErrorByCode(exception, SIGNER_ERROR_CODE.ENSURE_PROVIDER_ERROR)) {
            return;
        }
        if (isDevEnv()) {
            console.error(exception);
        }
        if (isSignerError(exception)) {
            return handler({
                title: exception.title,
                message: exception.details ?? exception.title,
                code: exception.code,
                error: exception,
            });
        }
        if (isWavesError(exception)) {
            handler({
                message: exception.message,
                code: exception.error,
                error: exception,
            });
        }
    };

    getTransferTxs = async ({
        recipient,
        assetId,
        limit,
        timeStart,
    }: {
        recipient?: addressId;
        assetId?: assetId;
        limit?: number;
        timeStart?: number;
    }): Promise<ITransferTransaction[]> => {
        const { data } = await axios.get(
            urlString(`${DATA_SERVICE_URL}/transactions/transfer`, {
                recipient,
                assetId,
                limit,
                timeStart,
            }),
        );

        return data.data.map(({ data }) => data);
    };
}

export default TransactionService;
