import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import type { IArtefact } from '$shared/types';

import { ROUTES } from '$/constants';
import { useRouteParams } from '$/hooks/react-router';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    num?: number;
}

const BreedingArtefact = ({ artefact, num }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { address } = useRouteParams(ROUTES.FARM);
    const linkToBreeding = useEffectiveCallback(() => {
        const pathToRedirect = generatePath(ROUTES.DUCKLINGS, { address });
        window.location.replace(pathToRedirect);
    });

    return (
        <AbstractArtefact
            artefact={artefact}
            onClick={linkToBreeding}
            footerButtonText={t('artefact.go_to_breeding')}
            num={num}
        />
    );
};

export default BreedingArtefact;
