import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

export default class TooltipItem extends Component {
    // static propTypes { content: PropTypes.string }
    state = {
        id: `id4tooltip_${new Date().getUTCMilliseconds()}${Math.floor(Math.random() * 10) + 1}${this.props.id}`,
        tooltipOpen: false,
    };

    toggle = () => this.setState(({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen }));

    render() {
        const { defaultOpen, ...restProps } = this.props;
        return [
            <Tooltip {...restProps} isOpen={this.state.tooltipOpen} toggle={this.toggle} target={this.state.id} key="1">
                {this.props.content}
            </Tooltip>,
            React.cloneElement(React.Children.only(this.props.children), {
                id: this.state.id,
                key: this.props.id,
            }),
        ];
    }
}
