import { useEffect } from 'react';

const setDvh = () => {
    const dvh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--dvh', `${dvh}px`);
};

/**
 * The hook sets dvh unit in CSS to use inner height of viewport.
 * It fixes bug of vh unit on mobile devices, which is calculated ignoring browser UI
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
const useDynamicVh = (): void => {
    useEffect(() => {
        setDvh();

        window.addEventListener('resize', setDvh);

        return () => window.removeEventListener('resize', setDvh);
    });
};

export default useDynamicVh;
