const FEATURE = {
    ARTEFACTS_ENABLED: 'efc3eb61-5ada-4209-9eef-3537c3de1e65',
    MAY_4TH_WARS_BG_ENABLED: 'fe10aa1b-3c98-4e10-b4fc-1b95649f1ae5',
    NEW_DUCK_PAGE_ENABLED: 'd28a08a0-d7fa-4433-be9c-41fc3e9944a2',
    SOCK_BONUS_ENABLED: '3a7ccb6c-8c18-4b56-8d72-899921c40d48',
    HUNT_DISABLED: 'f55b0a2b-c036-411a-8666-fa49279d2209',
    HALLOWEEN_ENABLED: 'eec6d596-d178-4344-b1fb-4576ab8a577c',
    SPICE_ON: '858e8a33-7489-4287-94b0-b1a5eed39553',
    HUNT_SHOW_DUCKS_ENABLED: '3de519de-b6bc-4228-a6a6-971d6a162a0e',
    ROPE_ENABLED: '858e8a33-7489-4287-94b0-b1a5eed39554',
    LEADERBOARD_ENABLED: '858e8a33-7489-4287-94b0-b1a5eed39555',
    SKILL_TREE_ENABLED: '858e8a33-7489-4287-94b0-b1a5eed39078',
    THANKSGIVING_EVENT_ENABLED: '5ae74fbd-0870-45d4-b6d6-fab5aef9c8d1',
    LIVENESS_VERIFICATION_ENABLED: '858e8a33-7489-4287-94b0-b1a5eed39556',
    RENT_ENABLED: 'a775756f-f337-49b5-b459-feea871dc3d2',
    POOL_ENABLED: '77bfd390-e044-11ed-b5ea-0242ac120002',
    TURTLE_ENABLED: 'f672704a-1fea-11ee-be56-0242ac120002',
    MUTANT_ENABLED: 'f672704a-1fea-11ee-be56-0242ac120003',
    REWARD_SHARE_ENABLED: '21a6959e-6dc4-11ee-b962-0242ac120002',
    STATISTICS_ENABLED: '1ed11cb6-87a2-11ee-b9d1-0242ac120002',
    BOOSTERS_ENABLED: '83e450aa-9206-11ee-b9d1-0242ac120002',
    CANINES_ENABLED: '680eae81-00f6-4d12-bd29-e7538a7d0390',
    FELINES_ENABLED: '6d8e5bc9-d621-4c9f-8ad5-c80a66d4566e',
    XMAS_ENABLED: '3a607191-cd7d-4978-9b2d-46d211d4a6bf',
};

export type FeatureKey = keyof typeof FEATURE;
export type FeatureID = (typeof FEATURE)[keyof typeof FEATURE];

export default FEATURE;
