const isFeatureActiveLocally = (key: string): boolean => {
    const features = localStorage.getItem('waves_ducks_features');

    if (!features) {
        return false;
    }

    return features.split(',').includes(key);
};

export default isFeatureActiveLocally;
