/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Reducer } from 'react';

import { CHANGE_SETTING, TOGGLE_SETTING } from '../actions/actions';

const initialSettings = {
    /* Layout fixed. Scroll content only */
    isFixed: true,
    /* Boxed layout */
    isBoxed: false,
    /* Toggle for the sidebar user block */
    showUserBlock: true,
    /* Enables layout horizontal */
    horizontal: false,
    /* Full size layout */
    useFullLayout: false,
    /* Hide footer */
    hiddenFooter: false,
};

const settingsReducer: Reducer<any, any> = (
    // eslint-disable-next-line default-param-last
    state = initialSettings,
    action: { type: string; name: string; value: string },
) => {
    switch (action.type) {
        case TOGGLE_SETTING: {
            return {
                ...state,
                [action.name]: !state[action.name],
            };
        }

        case CHANGE_SETTING: {
            return {
                ...state,
                [action.name]: action.value,
            };
        }

        default: {
            return state;
        }
    }
};

export default settingsReducer;
