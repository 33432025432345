// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_LANGUAGES = {
    en: {
        name: 'English',
        flag: '🇺🇸',
        tag: 'en',
    },
    ru: {
        name: 'Русский',
        flag: '🇷🇺',
        tag: 'ru',
    },
    cn: {
        name: '中国人',
        flag: '🇨🇳',
        tag: 'cn',
    },
    es: {
        name: 'Español',
        flag: '🇪🇸',
        tag: 'es',
    },
};
