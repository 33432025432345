import { v4 as generateUuid } from 'uuid';

const DEVICE_ID_KEY = 'waves-ducks:device-id';

const setDeviceId = (newDeviceId: string) => {
    localStorage.setItem(DEVICE_ID_KEY, newDeviceId);

    return newDeviceId;
};

const createNewDeviceId = () => generateUuid();

const getDeviceId = (): string => {
    const deviceId = localStorage.getItem(DEVICE_ID_KEY);

    if (!deviceId) {
        return setDeviceId(createNewDeviceId());
    }

    return deviceId;
};

export default getDeviceId;
