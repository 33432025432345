import cn from 'classnames';
import { FC, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './Wrap.module.scss';

interface IProps {
    isFullWidth?: boolean;
    className?: string;
    children?: ReactNode;
}

const Wrap: FC<IProps> = ({ isFullWidth, className, children }) => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [pathname]);

    return <div className={cn(st.wrap, { [st.fullWidth]: isFullWidth }, className)}>{children}</div>;
};

export default Wrap;
