import CommonWarsService from '$shared/services/wars';

import assetsService from '../assets';
import helperService from '../helper';

class FrontendWarsService extends CommonWarsService {
    helperService = helperService;

    assetsService = assetsService;
}

export default FrontendWarsService;
