import CommonFarmingService from '$shared/services/farming';

import helperService from '../helper';

class FrontendFarmingService extends CommonFarmingService {
    protected helperService = helperService;
}

const farmingService = new FrontendFarmingService();

export default farmingService;
