import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { IArtefact } from '$shared/types';

import AbstractArtefact from './AbstractArtefact';

interface Props {
    artefact: IArtefact;
    onUse?: (artefact: IArtefact) => void;
    num?: number;
}

const UsableOnArtefactArtifact = ({ artefact, onUse, num }: Props): JSX.Element => {
    const { t } = useTranslation();
    const use = useCallback(() => {
        onUse?.(artefact);
    }, [artefact, onUse]);

    return (
        <AbstractArtefact
            num={num}
            withoutLink
            artefact={artefact}
            onClick={use}
            footerButtonText={t('artefact.use')}
        />
    );
};

export default UsableOnArtefactArtifact;
