import { useMemo } from 'react';

import { AnimalType, IAnimalsParamsFromGenotype } from '$shared/types/animals';

import caninesService from '$/services/api/canines/instance';
import ducksService from '$/services/api/ducks';
import felinesService from '$/services/api/felines/instance';
import mutantsService from '$/services/api/mutants';
import turtlesService from '$/services/api/turtles';

interface IUseAnimalParams {
    assetId: string;
    name: string;
}

export enum animalsEnum {
    DUCK = 'duck',
    TURTLE = 'turtles',
    MUTANT = 'mutant',
    CANINE = 'canines',
    FELINE = 'felines',
}

const useAnimalParams = (
    details: IUseAnimalParams | undefined | null,
    animalsType?: AnimalType,
    shelf?: number | undefined,
    options: { cacheTs?: number } = {},
): IAnimalsParamsFromGenotype | undefined => {
    const { assetId, name } = details ?? {};
    const { cacheTs } = options;
    let animalTypeWithMtnt;

    if (animalsType === AnimalType.MUTANTS && shelf) {
        if (shelf === 1) animalTypeWithMtnt = AnimalType.DUCKS;
        else if (shelf === 2) animalTypeWithMtnt = AnimalType.TURTLES;
    } else animalTypeWithMtnt = animalsType;

    return useMemo(() => {
        if (name) {
            switch (true) {
                case animalTypeWithMtnt === AnimalType.DUCKS: {
                    const duckParameters = ducksService.getDuckParametersFromGenotype(name, { assetId, cacheTs });
                    const { duckDetails, ...rest } = duckParameters;
                    const animalParameters = {
                        ...rest,
                        animalDetails: duckDetails,
                    };
                    return animalParameters;
                }
                case animalTypeWithMtnt === AnimalType.TURTLES:
                    return turtlesService.getAnimalParametersFromGenotype(name, { assetId, cacheTs });
                case animalTypeWithMtnt === AnimalType.CANINES:
                    return caninesService.getAnimalParametersFromGenotype(
                        name,
                        { assetId, cacheTs },
                        animalsEnum.CANINE,
                    );
                case animalTypeWithMtnt === AnimalType.FELINES:
                    return felinesService.getAnimalParametersFromGenotype(
                        name,
                        { assetId, cacheTs },
                        animalsEnum.FELINE,
                    );
                case animalTypeWithMtnt === AnimalType.MUTANTS && !shelf: {
                    const mutantParameters = mutantsService.getMutantParametersFromGenotype(name, { assetId, cacheTs });
                    const { mutantInfo, ...rest } = mutantParameters;
                    const animalParameters = {
                        ...rest,
                        animalDetails: mutantInfo,
                    };
                    return animalParameters;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId, name, cacheTs, animalsType]);
};

export default useAnimalParams;
