import { getWearableItemGroupByType, isWearable } from '$shared/domain/items';
import CommonArtefactService from '$shared/services/artefact';
import type { AssetDetails, DuckConnectionType, IArtefact } from '$shared/types';

import { APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import accountBoosterService from '../account-boosters';
import assetsService from '../assets';
import auctionService from '../auctions';
import { duckHouseService, megaDuckHouseService, xmasStbleService } from '../duck-houses';
import helperService from '../helper';
import wearablesService from '../wearables';

class FrontendArtefactService extends CommonArtefactService {
    APP_URL = APP_URL;

    helperService = helperService;

    auctionService = auctionService;

    accountBoosterService = accountBoosterService;

    assetsService = assetsService;

    duckHouseService = duckHouseService;

    megaDuckHouseService = megaDuckHouseService;

    xmasStbleService = xmasStbleService;

    wearablesService = wearablesService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    fetchCosmeticsOnAddressByGroup = async (
        addressId: addressId,
    ): Promise<Record<DuckConnectionType, AssetDetails[]>> => {
        const nfts = await helperService.getNftsOnAddress(addressId);

        return nfts.reduce(
            (res, nft) => {
                const isCosmetic = isWearable(nft);

                if (!isCosmetic) {
                    return res;
                }
                const group = getWearableItemGroupByType(nft.name);
                if (res[group] == null) {
                    return {
                        ...res,
                        [group]: [nft],
                    };
                }
                return {
                    ...res,
                    [group]: [...res[group], nft],
                };
            },
            {} as Record<DuckConnectionType, AssetDetails[]>,
        );
    };

    getArtefactTags = (artefact: IArtefact | undefined | null): string[] => {
        const tags: string[] = [];

        if (!artefact) {
            return tags;
        }
        const { isStaked, owner, onSale } = artefact;

        if (owner === this.DAPP_ADDRESS) {
            tags.push('old_sc');
        }

        if (isStaked) {
            tags.push('is_active');
        }

        if (onSale) {
            tags.push('on_sale');
        }

        return tags;
    };
}

export default FrontendArtefactService;
