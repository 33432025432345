import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import { ITurtleDetailsLocked } from '$shared/types/turtles';

import turtlesService from '$/services/api/turtles';
import { isAssetId } from '$/utils/assets';

export const turtleDetailsQueryKey = (nftId: turtleId): string[] => ['turtle-details-v2', nftId];

const useTurtleDetailsV2 = (nftId: string): UseQueryResult<ITurtleDetailsLocked> =>
    useQuery(turtleDetailsQueryKey(nftId), () => turtlesService.fetchOneAnimalDetailsV2(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 20 * ONE_SECOND,
    });

export default useTurtleDetailsV2;
