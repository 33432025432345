import cn from 'classnames';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import turtleShadow from '$/assets/svg/turtles/turtle-shadow.svg';
import Swal from '$/components/Swal';

import { Card } from '../Card';
import st from './HatchedTurtleCard.module.scss';

interface IProps {
    turtleClaimedSwalOptions;
    className?: string;
    imgClassName?: string;
    onClick: () => void;
}

const HatchedTurtleCard: FC<IProps> = ({ turtleClaimedSwalOptions, onClick, className, imgClassName }) => (
    <>
        <Card
            isAuth
            className={cn(st.card, className)}
            cover={<img src={turtleShadow} alt="Hatched turtle" className={cn(st.img, imgClassName)} />}
            footer={{
                text: <Trans i18nKey="turtle_incubator.claim.claim_btn_ready" />,
                className: st.footer,
                buttonClassName: st.btn,
            }}
            onClick={onClick}
        />

        {turtleClaimedSwalOptions && <Swal options={turtleClaimedSwalOptions} />}
    </>
);

export default HatchedTurtleCard;
