/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';

/**
 * Works just like useCallback, but without need of passing deps array
 *
 *
 * const state = useSelector(someSelector);
 *
 * cb will not be recreated because there are no dependencies,
 * but the state value will always be the same as on the first render
 *
 * const cb = useCallback(() => { state }, [])
 *
 *
 * cb2 is recreated with each state change
 * and cb2 always has the actual value of state
 *
 * const cb2 = useCallback(() => { state }, [state])
 *
 *
 * cb3 is not recreated and always has the actual state value
 *
 * const cb3 = useEffectiveCallback(() => { state })
 *
 * @param handler
 */

const useEffectiveCallback = <Args extends any[], R>(handler: (...args: Args) => R): ((...args: Args) => R) => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    return useCallback((...args: Args) => savedHandler.current(...args), [savedHandler]);
};

export default useEffectiveCallback;
