import CommonDuckHouseService from '$shared/services/duck-house';

import assetsService from '../assets';
import helperService from '../helper';
import productionService from '../production';

class FrontendDuckHouseService extends CommonDuckHouseService {
    helperService = helperService;

    assetsService = assetsService;

    productionService = productionService;
}

export default FrontendDuckHouseService;
