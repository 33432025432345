import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DUCK_COLORS } from '$shared/constants';

import Title from '$/components/common/Title';

import ArGlassesImg from '../../../assets/svg/optic-glasses.svg';
import st from '../Artefact/Artefact.module.scss';
import { Card } from '../Card';

interface IProps {
    hideFooter?: boolean;
    onClick?: () => void;
    num?: number;
}

const ArGlasses: FC<IProps> = ({ hideFooter, onClick, num }) => {
    const { t } = useTranslation();

    return (
        <Card
            background={`#${DUCK_COLORS.U}`}
            cover={<img src={ArGlassesImg} alt={t('ar_glasses.select_duck_alt')} />}
            footer={{ hidden: hideFooter, text: t('ar_glasses.select_duck') }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                {t('ar_glasses.ar_glasses')}
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            <p className={st.text}>{t('ar_glasses.put_duck')}</p>
        </Card>
    );
};

export default ArGlasses;
