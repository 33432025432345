interface AuthDataSign {
    data: CustomData[];
    timestamp: number;
    signature: string;
    publicKey: string;
}

const AUTH_DATA_SIGN = 'AUTH_DATA_SIGN_DATA';

class AuthDataSignStore {
    public static get(): AuthDataSign | null {
        try {
            return JSON.parse(localStorage.getItem(AUTH_DATA_SIGN) as string);
        } catch (e) {
            return null;
        }
    }

    public static set(data: AuthDataSign): void {
        localStorage.setItem(AUTH_DATA_SIGN, JSON.stringify(data));
    }

    public static remove(): void {
        localStorage.removeItem(AUTH_DATA_SIGN);
    }
}

export default AuthDataSignStore;
