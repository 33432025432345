import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import { WithHuntParams } from '$shared/types/hunt';

import ducksService from '$/services/api/ducks';
import { isAssetId } from '$/utils/assets';

const duckHuntDetailsQueryKey = (nftId: duckId): string[] => ['duck-hunt-details-v2', nftId];

const useDuckHuntDetailsV2 = (nftId: string): UseQueryResult<WithHuntParams> =>
    useQuery(duckHuntDetailsQueryKey(nftId), () => ducksService.fetchOneDuckHuntDetailsV2(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 2 * ONE_SECOND,
    });

export default useDuckHuntDetailsV2;
