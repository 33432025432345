import cn from 'classnames';

import arrowIcon from './assets/arrow.svg';
import st from './NewsCardsContainer.module.scss';

interface Props {
    isVisible: boolean;
    onClick: () => void;
    direction: 'previous' | 'next';
}

const FlipButton = ({ direction, isVisible, onClick }: Props): JSX.Element => (
    <button type="button" className={cn(st.button, st[direction], { [st.visible]: isVisible })} onClick={onClick}>
        <img src={arrowIcon} alt={`${direction} news`} />
    </button>
);

export default FlipButton;
