import { useMemo } from 'react';

import type { IDuckParamsFromGenotype } from '$shared/types';

import ducksService from '$/services/api/ducks';

interface IUseDuckParams {
    assetId: string;
    name: string;
}

const useDuckParams = (
    details: IUseDuckParams | undefined | null,
    options: { cacheTs?: number } = {},
): IDuckParamsFromGenotype | undefined => {
    const { assetId, name } = details ?? {};
    const { cacheTs } = options;

    return useMemo(() => {
        if (name) {
            return ducksService.getDuckParametersFromGenotype(name, { assetId, cacheTs });
        }
    }, [assetId, name, cacheTs]);
};

export default useDuckParams;
