import cn from 'classnames';
import { createElement, FC, ReactNode } from 'react';

import st from './Title.module.scss';

export type TTitleSizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'm';

export interface IProps {
    as?: TTitleSizes | 'p' | 'span' | 'div';
    children?: ReactNode;
    className?: string;
    size?: TTitleSizes;
    onClick?: () => void;
}

const Title: FC<IProps> = ({ children, as = 'h1', size = 'h1', className, onClick, ...rest }) =>
    createElement(as, { className: cn(st[size], className), onClick, ...rest }, children);

export default Title;
