const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    LOGIN_SEED: '/login/seed',
    LOGIN_WITH_RETURN: '/login?returnUrl=:returnUrl',

    MARKETPLACE: '/marketplace/:tab?',
    MARKETPLACE_OFFERS: '/marketplace/offers',
    MARKETPLACE_OFFERS_STORE: '/marketplace/offers?itemType=store&cataloguePlacement=CONSUMABLE',
    MARKETPLACE_BIDS: '/marketplace/my-bids',
    MARKETPLACE_RENT: '/marketplace/rent',
    MARKETPLACE_STATISTICS: '/marketplace/statistics',
    OLD_MARKETPLACE: '/old/marketplace',

    LANDING: '/landing',
    LANDING_HUNT_LAUNCH: '/hunt/testimonials',

    FARM: '/portfolio/:address/:tab?',
    HATCH_DUCK: '/hatch-duck',
    HATCH_TURTLE: '/hatch-turtle',
    HATCH_CANINE: '/hatch-canine',
    HATCH_FELINE: '/hatch-feline',
    BREEDING: '/breeding',
    DUCK_BREEDING: '/breeding/ducks',
    TURTLES_BREEDING: '/breeding/turtles',
    MUTANTS_BREEDING: '/breeding/mutants',
    CANINES_BREEDING: '/breeding/canines',
    FELINES_BREEDING: '/breeding/felines',
    BREEDING_CALCULATOR: '/breeding-calculator',
    COLLECTIVE_FARMS: '/collective-farms',
    GUIDE: '/guide/:tab?',
    RAFFLE: '/raffle',
    ONBOARDING: '/onboarding',

    BALANCE: '/user-page/balance',
    HISTORY: '/user-page/history',
    REFERRALS: '/referrals/:address',
    SETTINGS: '/settings/:address',
    AS: '/as/:address/:publicKey?',

    CHOOSE_COLLECTIVE_FARM: '/choose-collective-farm',
    CHOOSE_DUCK: '/choose-duck',
    DUCK: '/duck/:nftId/:tab?',
    TURTLE: '/turtle/:nftId/:tab?',
    MUTANT: '/mutant/:nftId/:tab?',
    CANINE: '/canine/:nftId/:tab?',
    FELINE: '/feline/:nftId/:tab?',

    DUCK_NO_TAB: '/duck/:nftId',
    DUCK_MAIN_TAB: '/duck/:nftId/main',
    DUCK_HUNT_TAB: '/duck/:nftId/hunt',
    DUCK_WARS_TAB: '/duck/:nftId/wars',
    DUCK_FARMING_TAB: '/duck/:nftId/farming',
    DUCK_VEGG_FARMING_TAB: '/duck/:nftId/vegg-farming',
    DUCK_TRADE_TAB: '/duck/:nftId/trade',
    DUCK_REBIRTH_TAB: '/duck/:nftId/rebirth',
    DUCK_SEND_TAB: '/duck/:nftId/send',
    DUCK_MAKE_A_BID_TAB: '/duck/:nftId/make-a-bid',
    DUCK_RENT_TAB: '/duck/:nftId/rent',

    TURTLE_NO_TAB: '/turtle/:nftId',
    TURTLE_MAIN_TAB: '/turtle/:nftId/main',
    TURTLE_FARMING_TAB: '/turtle/:nftId/farming',
    TURTLE_SEND_TAB: '/turtle/:nftId/send',
    TURTLE_TRADE_TAB: '/turtle/:nftId/trade',
    TURTLE_MAKE_A_BID_TAB: '/turtle/:nftId/make-a-bid',
    TURTLE_REBIRTH_TAB: '/turtle/:nftId/rebirth',

    MUTANT_NO_TAB: '/mutant/:nftId',
    MUTANT_MAIN_TAB: '/mutant/:nftId/main',
    MUTANT_FARMING_TAB: '/mutant/:nftId/farming',
    MUTANT_SEND_TAB: '/mutant/:nftId/send',
    MUTANT_TRADE_TAB: '/mutant/:nftId/trade',
    MUTANT_MAKE_A_BID_TAB: '/mutant/:nftId/make-a-bid',
    CANINE_NO_TAB: '/canine/:nftId',
    CANINE_MAIN_TAB: '/canine/:nftId/main',
    CANINE_FARMING_TAB: '/canine/:nftId/farming',
    CANINE_SEND_TAB: '/canine/:nftId/send',
    CANINE_TRADE_TAB: '/canine/:nftId/trade',
    CANINE_MAKE_A_BID_TAB: '/canine/:nftId/make-a-bid',
    CANINE_REBIRTH_TAB: '/canine/:nftId/rebirth',

    FELINE_NO_TAB: '/feline/:nftId',
    FELINE_MAIN_TAB: '/feline/:nftId/main',
    FELINE_FARMING_TAB: '/feline/:nftId/farming',
    FELINE_SEND_TAB: '/feline/:nftId/send',
    FELINE_TRADE_TAB: '/feline/:nftId/trade',
    FELINE_MAKE_A_BID_TAB: '/feline/:nftId/make-a-bid',
    FELINE_REBIRTH_TAB: '/feline/:nftId/rebirth',

    DUCKLING: '/duckling/:nftId',
    DUCKLINGS: '/portfolio/:address/baby-ducks',
    FREE_DUCKLING: '/duckling/free-duckling',
    ITEM: '/item/:nftId',
    PETE: '/pete/:nftId',
    PERMISSIONLESS: '/permissionless',
    PERMISSIONLESS_PROMO: '/permissionless/promo/:code',
    EVENT: '/event/:eventName',
    CONSENSUS: '/event/consensus',
    CONSENSUS_PROMO: '/event/consensus/promo/:code',
    NFT_NYC: '/event/nft-nyc',
    NFT_NYC_PROMO: '/event/nft-nyc/promo/:code',
    METAVERSE_DAY: '/event/metaverse-day',
    REBIRTH: '/rebirth-duck/:nftId',
    REBIRTH_TURTLE: '/rebirth-turtle/:nftId',
    REBIRTH_CANINE: '/rebirth-canine/:nftId',
    REBIRTH_FELINE: '/rebirth-feline/:nftId',
    SHAKEIT: '/shake-it/:nftId',
    WELCOME: '/welcome',
    BUY_EGG: '/get-egg',
    BUY_EGG_EXCHANGES: '/get-egg/exchanges',

    PLAY_AND_EARN_MODAL: '/play-and-earn',

    FARMS_LEADERBOARD: '/promo/fight/farms/leaderboard',
    FIGHT: '/promo/fight',
    VICTORY: '/promo/victory',
    LOST: '/promo/lost',
    NO_WINNER: '/promo/no-winners',

    HUNT: '/hunt',
    HUNT_LANDING: '/hunt/intro/:lang?',
    HUNT_LANDING_US: '/hunt/intro/us',
    HUNT_LANDING_UK: '/hunt/intro/uk',
    HUNT_LANDING_ES: '/hunt/intro/es',
    HUNT_LANDING_DE: '/hunt/intro/de',
    HUNT_LANDING_JP: '/hunt/intro/jp',
    HUNT_LANDING_RU: '/hunt/intro/ru',
    HUNT_LANDING_PH: '/hunt/intro/ph',
    HUNT_LANDING_KR: '/hunt/intro/kr',
    HUNT_PROFILE: '/hunt/profile',
    HUNT_PROFILE_SKILL_TREE: '/hunt/profile/skilltree',
    HUNT_PROFILE_PETS: '/hunt/profile/pets',
    HUNT_REWARDS: '/hunt/rewards',
    HUNT_MARKETPLACE: '/hunt/marketplace',
    HUNT_MAP: '/hunt/map',
    HUNT_NEED_DUCK: '/hunt/needaduck',
    HUNT_GET_EGG: '/hunt/get-egg',
    HUNT_NEED_DUCK_MARKETPLACE: '/hunt/needaduck/marketplace',
    HUNT_NEED_DUCK_MY_DUCKS: '/hunt/needaduck/my-ducks',
    HUNT_DUCK: '/hunt/duck/:nftId',
    HUNT_LEADERBOARD: '/hunt/leaderboard',

    ADMIN_HUNT: '/admin/hunt',
    ADMIN_DUCKLING: '/admin/hunt/duckling',
    ADMIN_WARS: '/admin/wars/banned',
    ADMIN_WARS_MATCHES: '/admin/wars/matches',
    ADMIN_WARS_ROUNDS: '/admin/wars/rounds',
    ADMIN_HUNT_CAUGHT_EGGS: '/admin/hunt/caught-eggs',
    ADMIN_HUNT_DELETED_EGGS: '/admin/hunt/deleted-eggs',
    ADMIN_HUNT_DASHBOARD: '/admin/hunt/dashboard',
    ADMIN_HUNT_NOT_PAID_USERS: '/admin/hunt/not-paid-users',
    ADMIN_HUNT_CALENDAR: '/admin/hunt/calendar',
    ADMIN_HUNT_PLAYER: '/admin/hunt/player',

    RENTPLACE: '/rentplace',

    POOL: '/pool',

    ITEM_CRAFTING: '/item-crafting',

    PRIVACY_POLICY: '/privacy-policy',

    STATISTICS: '/statistics/:option?',
    WARS_STATS_DETAILS: '/wars-stats-details/:lapid',
    WARS_STATS_PLAYERS_DETAILS: '/wars-stats-players/:lapid',
} as const;

export default ROUTES;
