import * as Sentry from '@sentry/react';

import { isDevEnv } from '$shared/utils';

import consoleInterceptors from './console-interceptors';

const loggerService = {
    init: (): void => {
        if (isDevEnv() && process.env.SENTRY !== 'enabled') {
            console.warn('Sentry is disabled in development mode');
            return;
        }
        const originalConsole = window.console;

        window.console = {
            ...originalConsole,
            log: (...args) => {
                consoleInterceptors.log?.forEach((interceptor) => {
                    interceptor(...args);
                });
                originalConsole.log(...args);
            },
        };

        Sentry.init({
            dsn: 'https://b91d3b7d0f6b71fa65739fcdc41dfa1a@sentry.blackturtle.eu/2',
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.browserProfilingIntegration(),
                Sentry.captureConsoleIntegration({ levels: ['error'] }),
                Sentry.replayIntegration({
                    // Additional SDK configuration goes in here, for example:
                    maskAllText: true,
                    blockAllMedia: true,
                }),
                Sentry.contextLinesIntegration(),
                Sentry.debugIntegration(),
                Sentry.httpClientIntegration(),
                Sentry.replayCanvasIntegration(),
                Sentry.sessionTimingIntegration(),
                Sentry.extraErrorDataIntegration(),
            ],
            tracesSampleRate: 0.2,
            profilesSampleRate: 0.2,
            replaysSessionSampleRate: 0.2,
            replaysOnErrorSampleRate: 0.2,
            debug: isDevEnv(),
            environment: process.env.NODE_ENV,
            attachStacktrace: true,
            sendDefaultPii: true,
            initialScope: {
                tags: {
                    service: 'web',
                },
            },
        });
        console.log('Sentry is enabled');
    },
    captureException: (exception): string | void =>
        isDevEnv() ? console.error(exception) : Sentry.captureException(exception),
};

export default loggerService;
