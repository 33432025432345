import CommonMutantsFarmingService from '$shared/services/mutants-farming';

import helperService from '../helper';

class FrontendMutantsFarmingService extends CommonMutantsFarmingService {
    protected helperService = helperService;
}

const farmingMutantsService = new FrontendMutantsFarmingService();

export default farmingMutantsService;
