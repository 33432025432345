import { useEffect, useState } from 'react';

import productionService from '$/services/api/production';

const use100RarityEggProduction = (): string => {
    const [eggProduction, setEggProduction] = useState('...');

    useEffect(() => {
        // TODO; calculate this dynamiccaly
        productionService.getEggProduction(8000).then(setEggProduction);
    }, []);

    return eggProduction;
};

export default use100RarityEggProduction;
