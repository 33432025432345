/* eslint-disable @typescript-eslint/no-explicit-any */
import APP_CONFIGS, { AppConfigs } from '$shared/constants/configs';

import configsMap from './configsMap';

function getConfig<K1 extends keyof AppConfigs>(key: K1): AppConfigs[K1];
function getConfig<K1 extends keyof AppConfigs, K2 extends keyof AppConfigs[K1]>(
    key1: K1,
    key2: K2,
): AppConfigs[K1][K2];
function getConfig<K1 extends keyof AppConfigs, K2 extends keyof AppConfigs[K1], K3 extends keyof AppConfigs[K1][K2]>(
    key1: K1,
    key2: K2,
    key3: K3,
): AppConfigs[K1][K2][K3];
function getConfig<
    K1 extends keyof AppConfigs,
    K2 extends keyof AppConfigs[K1],
    K3 extends keyof AppConfigs[K1][K2],
    K4 extends keyof AppConfigs[K1][K2][K3],
>(key1: K1, key2: K2, key3: K3, key4: K4): AppConfigs[K1][K2][K3][K4];
function getConfig<Keys extends [keyof AppConfigs, ...string[]]>(...keys: Keys): Get<Keys, AppConfigs> {
    const key = keys.reduce((config: AppConfigs | string, key) => config[key], APP_CONFIGS as any);

    return configsMap?.get(key) as Get<Keys, AppConfigs>;
}

export default getConfig;
