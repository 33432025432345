import { base58Decode } from '@waves/ts-lib-crypto';

// eslint-disable-next-line import/prefer-default-export
export const isAssetId = (val: string): val is assetId => {
    try {
        const byteArray = base58Decode(val);

        return Uint8Array.from(byteArray).length === 32;
    } catch (e) {
        return false;
    }
};
