import { QueryKey, useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';

import type { UserAnnouncement } from '$shared/types';

import useAuth from '$/hooks/useAuth';
import useInvalidateQueryCache from '$/hooks/useInvalidateQueryCache';
import userAnnouncementsService from '$/services/api/user-announcements';
import { Announcement } from '$/types';

const userAnnouncementsQueryKey = (
    userAddress: string,
    ...params: [params?: { isNew?: boolean; isViewed?: boolean }]
): QueryKey => ['user', userAddress, 'announcements', ...params];

function transformToAnnouncements(announcements: UserAnnouncement[]): Announcement[] {
    return announcements.map(({ announcement }) => ({
        id: announcement.key,
        imgUrl: announcement.imageUri,
        title: `news.items.${announcement.key}.title`,
        text: `news.items.${announcement.key}.text`,
        releaseDateTime: new Date(announcement.releaseDateTime),
        expireDateTime: new Date(announcement.expireDateTime),
        link: {
            external: announcement.links[0]?.external,
            href: `news.items.${announcement.key}.link.href`,
            text: `news.items.${announcement.key}.link.text`,
        },
    }));
}

export const useUserAnnouncements = (
    params: {
        isNew?: boolean;
        isViewed?: boolean;
    } = {},
): UseQueryResult<Announcement[]> => {
    const { userAddress } = useAuth();

    return useQuery(
        userAnnouncementsQueryKey(userAddress, params),
        async () => userAnnouncementsService.getAll(userAddress, params),
        {
            select: transformToAnnouncements,
            staleTime: Infinity,
        },
    );
};

export const useUnseenAnnouncements = (): UseQueryResult<Announcement[]> =>
    useUserAnnouncements({
        isNew: true,
        isViewed: false,
    });

export const useChangeUserAnnouncement = (): UseMutationResult<
    Partial<UserAnnouncement>,
    unknown,
    { announcementKey: string; isViewed: boolean }
> => {
    const { userAddress, isAuth } = useAuth();
    const invalidateQueryCache = useInvalidateQueryCache(['user', userAddress, 'announcements']);

    return useMutation(
        userAnnouncementsQueryKey(userAddress),
        async ({ announcementKey, isViewed }) => {
            if (!isAuth) {
                return {};
            }
            return userAnnouncementsService.set(userAddress, announcementKey, isViewed);
        },
        {
            onSuccess: () => invalidateQueryCache(),
        },
    );
};

export const useViewAllUserAnnouncements = (): UseMutationResult<UserAnnouncement[]> => {
    const { userAddress } = useAuth();
    const invalidateQueryCache = useInvalidateQueryCache(['user', userAddress, 'announcements']);

    return useMutation(userAnnouncementsQueryKey(userAddress), () => userAnnouncementsService.viewAll(userAddress), {
        onSuccess: () => invalidateQueryCache(),
    });
};
