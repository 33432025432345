import { Client, Room } from 'colyseus.js';

import { sha256 } from '@waves/ts-lib-crypto';

import { calculateDefencePointsCount } from '$shared/domain/gameplay';
import { GameplayActionType, GameplayEvents, MatchmakingMessage } from '$shared/enums';
import { log } from '$shared/services/log';
import type {
    Gameplay,
    GameplayPlayer,
    GameplayRound,
    GameplayTransactionType,
    GameplayTurnState,
} from '$shared/types';

import { AuthDataSignService, authService } from '../authentication';

// const PREVIOUS_SESSION_KEY = 'PREVIOUS_GAMEPLAY_SESSION';
// const RECONNECTION_MAX_TIME = 15 * 60e3;

export default class GameplayService {
    static client: Client;

    static fightRoom: Room<Gameplay>;

    private static handlers = new Array<(state: Gameplay) => void>();

    private static messagesHandlers: { [key in GameplayEvents]?: Array<(state: Gameplay) => void> } = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static matchmakingRoom: Room<any>;

    static hasPlayerSignedMatch = false;

    static isOpponentFound = false;

    static get sessionId(): string | void {
        return this.fightRoom?.sessionId;
    }

    static get gameplayState(): Gameplay {
        // @ts-expect-error TODO: FIX THIS
        if (!this.fightRoom) return void 0;
        return this.fightRoom.state;
    }

    static subscribeForState(handler: (gameplay: Gameplay) => void): () => void {
        this.handlers.push(handler);
        return () => {
            const idx = this.handlers.indexOf(handler);
            if (idx !== -1) this.handlers.splice(idx, 1);
        };
    }

    static subscribeForMessage(msgType: GameplayEvents, handler: (state: Gameplay) => void): () => void {
        if (!this.messagesHandlers[msgType]) this.messagesHandlers[msgType] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handlers = this.messagesHandlers[msgType] as any;
        handlers.push(handler);
        return () => {
            const idx = handlers.indexOf(handler);
            if (idx !== -1) this.messagesHandlers[msgType]?.splice(idx, 1);
        };
    }

    public static getAuthToken(): string {
        const localStorageData = window.localStorage.getItem('angle-store-key');
        if (!localStorageData) throw new Error();

        return JSON.parse(localStorageData).auth.address;
    }

    // private static async tryReconnect(): Promise<Room<Gameplay> | void> {
    //     try {
    //         const prevSessionRaw = localStorage.getItem(PREVIOUS_SESSION_KEY);
    //         if (!prevSessionRaw) return;
    //         const prevSession: IPreviousGameplaySession = JSON.parse(prevSessionRaw);
    //         if (
    //             !prevSession.sessionId ||
    //             !prevSession.gameId ||
    //             !prevSession.serverHost ||
    //             Date.now() - prevSession.startedAt > RECONNECTION_MAX_TIME
    //         )
    //             return;
    //         this.client = new Client(prevSession.serverHost);
    //         return await this.client.reconnect(prevSession.gameId, prevSession.sessionId);
    //     } catch (e) {}
    // }

    static async matchMaking(client: Client, options): Promise<Room> {
        const matchmakingRoom = await client.joinOrCreate('MatchmakingRoom', options);
        this.matchmakingRoom = matchmakingRoom;

        return new Promise((resolve, reject) => {
            matchmakingRoom.onLeave((code) => reject(new Error(`error code: ${code}`)));
            matchmakingRoom.onError((code) => reject(new Error(`error code: ${code}`)));
            matchmakingRoom.onMessage(MatchmakingMessage.OPPONENT_FOUND, async ({ reservation }) => {
                matchmakingRoom.removeAllListeners();
                matchmakingRoom.leave(true);
                const room = await client.consumeSeatReservation(reservation);
                if (room) {
                    this.isOpponentFound = true;
                    resolve(room);
                } else {
                    reject();
                }
            });
        });
    }

    static async leaveMatchMaking(): Promise<void> {
        if (this.matchmakingRoom) {
            this.matchmakingRoom.removeAllListeners();
            await this.matchmakingRoom.leave();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static async joinGame(server: string, fightId: string): Promise<any> {
        if (this.fightRoom) await this.leaveGame();
        const ssl = window.location.protocol === 'https:' || !server.includes('localhost');
        const serverHost = `${ssl ? 'wss' : 'ws'}://${server}`;
        this.client = new Client(serverHost);
        // const reconnectedRoom = await this.tryReconnect();
        // if (reconnectedRoom) {
        //     this.room = reconnectedRoom as any;
        // } else {
        try {
            this.fightRoom = (await this.matchMaking(this.client, {
                ...AuthDataSignService.authParams,
                fightId,
            })) as Room;
            // localStorage.setItem(
            //     PREVIOUS_SESSION_KEY,
            //     JSON.stringify({
            //         server,
            //         fightId,
            //         gameId: this.fightRoom.id,
            //         sessionId: this.fightRoom.sessionId,
            //         startedAt: Date.now(),
            //     } as IPreviousGameplaySession),
            // );
        } catch (e) {
            console.error(e);
            // eslint-disable-next-line no-alert
            if (window.confirm(`Something went wrong. Reload the page? Debug Error: ${JSON.stringify(e)}`)) {
                window.location.reload();
            }
            return Promise.reject();
        }
        // }
        this.fightRoom.onLeave((code) => {
            if (this.fightRoom?.state?.winner || code === 1000 || code === 4000) return;
            log(`Connection error. code: `, code);
            // eslint-disable-next-line no-alert
            if (window.confirm(`Something went wrong. Reload the page? Debug Leave event: ${code}`)) {
                window.location.reload();
            }
        });
        this.fightRoom.onStateChange((state) => {
            this.handlers.forEach((handler) => handler(state));
        });
        this.fightRoom.onMessage('*', (type, data) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (this.messagesHandlers.hasOwnProperty(type as any)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.messagesHandlers[type as any].forEach((handler) => handler(data));
            }
        });

        // if (reconnectedRoom) {
        //     return new Promise((resolve) => {
        //         this.room?.onStateChange.once(() => {
        //             resolve(this.getJoinGameData());
        //         });
        //     });
        // }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Promise<any>((resolve) => {
            this.fightRoom?.onMessage(GameplayEvents.WAIT_FOR_SIGN, () => {
                log('wait for sign');
                resolve(this.getJoinGameData());
            });
        });
    }

    static async leaveGame(): Promise<void> {
        log('leave game');
        if (this.fightRoom) {
            await this.fightRoom.leave(true);
            // localStorage.removeItem(PREVIOUS_SESSION_KEY);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.fightRoom = undefined as any;
    }

    static getJoinGameData(): { gameId: string; playerId: string; opponentId: string } {
        const players = Array.from(this.fightRoom.state.players.entries());
        const playerId = players[0][1].id === this.getAuthToken() ? players[0][0] : players[1][0];
        const opponentId = players[0][0] !== playerId ? players[0][0] : players[1][0];

        return {
            gameId: this.fightRoom.id,
            playerId,
            opponentId,
        };
    }

    static signGame(
        transaction: GameplayTransactionType,
        unHashedPlayersData: Array<{
            key: string;
            type: string;
            value: string;
        }>,
    ): void {
        this.fightRoom?.send(GameplayEvents.SIGNED, { transaction, unHashedPlayersData });
    }

    static async makeTurn(turnState: GameplayTurnState): Promise<void> {
        this.fightRoom?.send(GameplayEvents.MAKE_TURN, turnState);

        return new Promise((resolve) => {
            this.fightRoom?.onMessage(GameplayEvents.NEXT_ROUND, () => {
                resolve();
            });
        });
    }

    static currentRound(): GameplayRound {
        return this.gameplayState.rounds[this.gameplayState.currentRound];
    }

    static getInitialTurnState(): GameplayTurnState {
        return {
            selectedMove: null,
            targets: {
                [GameplayActionType.Attack]: [],
                [GameplayActionType.Defense]: [],
            },
        };
    }

    static async signGameProcess(startStage: boolean, authType: string): Promise<void> {
        const timestamp = this.gameplayState?.createdAt || 0;
        const unHashedPlayersData = Array.from(this.gameplayState?.players.values() ?? []).map(({ id }, idx) => ({
            key: `player_${idx + 1}`,
            type: 'string',
            value: id,
        }));
        const playersData = Array.from(this.gameplayState?.players.values() ?? []).map(({ id }, idx) => {
            const encodedAddress = new TextEncoder().encode(
                id + (this.gameplayState?.id ? this.gameplayState.id : 'gameplayStateIdNotAvailable'),
            );

            return {
                key: `player_${idx + 1}`,
                type: 'string',
                value: sha256(encodedAddress).join(''),
            };
        });
        const txData = [
            { key: 'gameId', type: 'string', value: this.gameplayState?.id },
            ...playersData,
            { key: 'timestamp', type: 'integer', value: timestamp },
        ];
        if (!startStage) {
            const winner =
                this.gameplayState?.winner === 'NOBODY'
                    ? 'NOBODY'
                    : this.gameplayState?.players[this.gameplayState?.winner as string].id;
            const encodedWinner = new TextEncoder().encode(
                winner + (this.gameplayState?.id ? this.gameplayState.id : 'gameplayStateIdNotAvailable'),
            );

            txData.unshift({
                key: 'winner',
                type: 'string',
                value: sha256(encodedWinner).join(''),
            });
        }
        const signature = await authService.signGameData(authType, txData);
        const { k: publicKey } = AuthDataSignService.authParams as { k: string };
        GameplayService.signGame(
            {
                timestamp,
                data: txData,
                signatures: [signature],
                publicKeys: [publicKey],
            },
            unHashedPlayersData,
        );
    }

    public static checkTurnIsValid(turnState: GameplayTurnState, player: GameplayPlayer): boolean {
        return (
            !!turnState.selectedMove &&
            Array.from(turnState.selectedMove.actions.entries()).every(([actionType, action]) => {
                log(action.count, turnState.targets, actionType);
                return actionType === GameplayActionType.Defense
                    ? turnState.targets[actionType]?.length ===
                          calculateDefencePointsCount(
                              action.count || 0,
                              player.duck.rarity,
                              player.duck.achievements.a.length,
                              player.bonusShield,
                          )
                    : action.count === turnState.targets[actionType]?.length;
            })
        );
    }

    public static checkIsFrozen(player: GameplayPlayer): boolean {
        return player.turnsFrozen > 0;
    }

    public static frozenTurns(player: GameplayPlayer): number {
        return player.turnsFrozen;
    }
}

// interface IPreviousGameplaySession {
//     server: string;
//     gameId: string;
//     fightId: string;
//     sessionId: string;
//     startedAt: number;
//     serverHost: string;
// }
