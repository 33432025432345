import { useTranslation } from 'react-i18next';

import { ArtefactType } from '$shared/types';

import { maxToFeedWithSignatureQueryKey } from '$/hooks/baby-ducks/useMaxToFeedWithSignature';
import useAuth from '$/hooks/useAuth';
import useEffectiveCallback from '$/hooks/useEffectiveCallback';
import useInvalidateQueryCache from '$/hooks/useInvalidateQueryCache';
import transactionService from '$/services/api/transaction';
import { authService } from '$/services/authentication';
import { showErrorMessage, showSuccessMessage } from '$/services/utils';

import useOracleStaticValue from '../oracle/useOracleStaticValue';

interface Artefact {
    assetId: artefactId;
    type: ArtefactType;
}

interface HandlerParams<A extends Artefact> {
    onUse?: (artefact: A) => void;
}

const useMutariumArtefactHandler = <A extends Artefact>(artefact: A, params?: HandlerParams<A>): (() => void) => {
    const { assetId } = artefact;
    const { t } = useTranslation();
    const { authType, userAddress } = useAuth();
    const invalidateQueryCache = useInvalidateQueryCache(maxToFeedWithSignatureQueryKey(userAddress));
    const { data: extraFee } = useOracleStaticValue('extraFee');

    return useEffectiveCallback(async () => {
        try {
            const { waitForTransaction } = await transactionService.invokeAuthService(
                authService.redeemMutuarium,
                authType,
                assetId,
                extraFee,
            );
            await waitForTransaction();
            await invalidateQueryCache();
            await params?.onUse?.(artefact);
            showSuccessMessage(t, 'Used successfully!');
        } catch (e) {
            transactionService.handleException(e, ({ message }) => showErrorMessage(t, message, e));
        }
    });
};

const useMutariumHandler = <A extends Artefact>(artefact: A, params?: HandlerParams<A>): (() => void) => {
    const { type } = artefact;
    const mutariumHandler = useMutariumArtefactHandler(artefact, params);
    const artMutariums = ['ART-MUTARIUM-D', 'ART-MUTARIUM-G'];

    if (artMutariums.some((artMutarium) => artMutarium === type)) {
        return mutariumHandler;
    }

    return () => void 0;
};

export default useMutariumHandler;
