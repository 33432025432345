/* eslint-disable camelcase */
import { isDevEnv } from '$shared/utils';

let sessionUnificationEnabled = false;
declare global {
    interface Window {
        ym;
        gtag;
        mainMetrikaId: string;
    }
}

export default function reachGoal(
    goal: string,
    params: Record<string, unknown> | null,
    action?: string,
    label?: string,
): void {
    if (isDevEnv()) {
        return;
    }
    enableSessionUnification();
    const finalParams = params ?? {};
    try {
        globalThis.ym(globalThis.mainMetrikaId, 'reachGoal', goal, finalParams);
    } catch (e) {}

    try {
        globalThis.gtag('event', goal, { event_label: action, event_category: label, ...finalParams });
    } catch (e) {}
}

export function reachGoalOnce(goal: string, action?: string, label?: string): void {
    try {
        const rawValue: string | null = localStorage.getItem(`${goal}_GOAL_SENT`);
        const result: boolean = JSON.parse(rawValue as string);
        if (result) return;
        reachGoal(goal, null, action, label);
        localStorage.setItem(`${goal}_GOAL_SENT`, JSON.stringify(true));
    } catch (e) {}
}

export function enableSessionUnification(): void {
    if (sessionUnificationEnabled) return;
    const localStorageData = window.localStorage.getItem('angle-store-key');
    if (!localStorageData) return;
    try {
        const authData = JSON.parse(localStorageData).auth;
        if (!authData.address || isDevEnv()) {
            return;
        }
        globalThis.gtag('config', 'UA-202537264-1', { user_id: authData.address });

        sessionUnificationEnabled = true;
    } catch (e) {
        console.error(e);
    }
}
