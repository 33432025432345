import axios from 'axios';

import type { UserAnnouncement } from '$shared/types';

import { API_URL } from '$/constants';

const viewAll = async (address: addressId): Promise<UserAnnouncement[]> => {
    try {
        const { data } = await axios.patch<UserAnnouncement[]>(`${API_URL}/v1/users/${address}/announcements`, {
            isViewed: true,
        });

        return data ?? [];
    } catch (e) {
        return [];
    }
};

export default viewAll;
