import { ComponentProps, ReactElement, ReactNode, SyntheticEvent } from 'react';

import { AdminEgg } from '$shared/types';
import { ICheat, ICheatAdmin } from '$shared/types/hunt';

import Button from '$/components/common/Button';

export enum EDuckTabs {
    HUNT = 'hunt',
    MAIN = 'main',
    WARS = 'wars',
    FARMING = 'farming',
    TRADE = 'trade',
    REBIRTH = 'rebirth',
    SEND = 'send',
    MAKE_A_BID = 'make-a-bid',
    RENT = 'rent',
    VEGG_FARMING = 'vegg-farming',
}

export type TDuckCardFooter = {
    isAuth?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    buttonClassName?: string;
    buttonSize?: ComponentProps<typeof Button>['size'];
    className?: string;
    dropdown?: JSX.Element | null;
    secondButton?: {
        disabled?: boolean;
        text: string | ReactNode;
        to?: string;
        onClick?: () => void;
    };
    bottomButton?: {
        disabled?: boolean;
        text: string | ReactNode;
        to?: string;
        onClick?: () => void;
    };
    component?: ReactElement;
    text?: ReactNode;
    to?: string;
    isMutant?: boolean;
    onClick?: (event: SyntheticEvent) => void;
};

export type AdminEggWithCheats = AdminEgg & { cheats: ICheat[] };

export type AdminUserWithEggs = {
    userAddress: string;
    receiverDeviceId: string;
    count: number;
    reward: number;
    cheats: ICheatAdmin[];
    ducks: Array<{ count: number }>;
    name?: string;
    nickname?: string;
    trustworthy?: boolean;
    referrer?: string;
    referrerName?: string;
    referrerNickname?: string;
    referrerTrustworthy?: boolean;
    levelOneReference?: string;
    referrerRewardAmount?: number;
};

export enum EHuntEggRewardStatus {
    Pending = 'pending',
    Submitted = 'submitted',
    Paying = 'paying',
    FailedToPay = 'failedToPay',
    Paid = 'paid',
}

export type AdminHuntDailyCaughtEggsReward = {
    _id: string;
    date: Date;
    eggIds: string[];
    cheats: ICheat[];
    status: EHuntEggRewardStatus;
    submittedAdminAddress: string;
};

export type AdminHuntCaughtEggsStatsForCalendar = {
    _id: string;
    count: number;
    date: string;
    reward: number;
    notPaidEggsCount?: number;
    notPaidEggsReward?: number;
};

export type AdminPlayerInfo = {
    eggs: integer;
    isSubmittedCheater: boolean;
    cheats: integer;
    notPaidEggs: integer;
    paidEggs: integer;
    paidReward: integer;
    eggsCalendar: AdminHuntCaughtEggsStatsForCalendar[];
};
