import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TURTLE_COLORS } from '$shared/constants';

import Title from '$/components/common/Title';
import { getBeachUrl } from '$/utils/cards';

import st from '../Artefact/Artefact.module.scss';
import { Card } from '../Card';

interface IProps {
    hideFooter?: boolean;
    color: string;
    onClick?: () => void;
    num?: number;
}

const EmptyBeach: FC<IProps> = ({ hideFooter, color, onClick, num }) => {
    const { t } = useTranslation();

    return (
        <Card
            background={`#${TURTLE_COLORS[color]}`}
            cover={<img src={getBeachUrl(color)} alt={t('beach.select_turtle_alt')} />}
            footer={{ hidden: hideFooter, text: t('beach.select_turtle') }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                {t('beach.name')}
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            <p className={st.text}>{t('beach.put_turtle')}</p>
        </Card>
    );
};

export default EmptyBeach;
