/* eslint-disable @typescript-eslint/no-explicit-any */
import { CANINE_GENERATION_NAMES } from '$shared/constants/canines';
import { getAddress } from '$shared/domain/constants';
import { ANIMAL_PREFIXES } from '$shared/enums';
import CommonAnimalsBreedingService from '$shared/services/animals-breeding/CommonAnimalsBreedingService';
import { AnimalBreedingDataMatch } from '$shared/types/animals';

import { DUXPLORER_URL } from '$/constants';

import assetsService from '../assets';
import helperService from '../helper';
import transactionService from '../transaction';

const CANINES_INCUBATOR_DAPP = getAddress('CANINES_INCUBATOR_DAPP');
const CANINES_BREEDER_DAPP = getAddress('CANINES_BREEDER_DAPP');

class FrontendCaninesBreedingService extends CommonAnimalsBreedingService {
    GENERATION_NAMES = CANINE_GENERATION_NAMES;

    DAPP_ADDRESS = CANINES_BREEDER_DAPP;

    INCUBATOR_DAPP_ADDRESS = CANINES_INCUBATOR_DAPP;

    ANIMAL_PREFIXES = ANIMAL_PREFIXES.CANI;

    DUXPLORER_URL = DUXPLORER_URL;

    helperService = helperService;

    transactionService = transactionService;

    assetsService = assetsService;

    protected ANIMAL_BREEDING_DATA_MATCH = AnimalBreedingDataMatch.CANINE;
}

export default FrontendCaninesBreedingService;
