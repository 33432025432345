import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IArtefact } from '$shared/types';

import { userWavesBalanceQueryKey } from '$/hooks/balances';
import artefactService from '$/services/api/artefact';
import transactionService from '$/services/api/transaction';
import { authService } from '$/services/authentication';
import { showErrorMessage, showSuccessMessage } from '$/services/utils';

import useOracleStaticValue from '../oracle/useOracleStaticValue';
import useAuth from '../useAuth';
import useEffectiveCallback from '../useEffectiveCallback';
import useInvalidateQueryCache from '../useInvalidateQueryCache';
import { artefactQueryKey } from './useArtefact';

const useStakeableItemHandler = ({ assetId, type, isStaked }: IArtefact): (() => Promise<void>) => {
    const { t } = useTranslation();
    const { authType, userAddress } = useAuth();
    const invalidateQueryCache = useInvalidateQueryCache(
        artefactQueryKey(assetId),
        userWavesBalanceQueryKey(userAddress),
    );
    const { data: extraFee } = useOracleStaticValue('extraFee');

    const isStakeable = useMemo(() => artefactService.isStakeable(type), [type]);

    const activate = useEffectiveCallback(async () => {
        try {
            const { waitForTransaction } = await transactionService.invokeAuthService(
                authService.stakeItem,
                authType,
                assetId,
                extraFee,
            );
            await waitForTransaction();
            await invalidateQueryCache();
            showSuccessMessage(t, 'Staked successfully!');
        } catch (e) {
            transactionService.handleException(e, ({ message }) => showErrorMessage(t, message, e));
        }
    });

    const deactivate = useEffectiveCallback(async () => {
        try {
            const { waitForTransaction } = await transactionService.invokeAuthService(
                authService.unstakeItem,
                authType,
                type,
                extraFee,
            );
            await waitForTransaction();
            await invalidateQueryCache();
            showSuccessMessage(t, 'Unstaked successfully!');
        } catch (e) {
            transactionService.handleException(e, ({ message }) => showErrorMessage(t, message, e));
        }
    });

    return useMemo(() => {
        if (!isStakeable) {
            return Promise.resolve;
        }

        return isStaked ? deactivate : activate;
    }, [isStakeable, isStaked, deactivate, activate]);
};

export default useStakeableItemHandler;
