/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import * as dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { createRoot } from 'react-dom/client';

import loggerService from '$/services/LoggerService';
import { AuthDataSignService } from '$/services/authentication';
import queryClient from '$/services/query-client';
import { store } from '$/store';

import App from './App';
import './i18n';
import AppProvider from './providers';

loggerService.init();

declare global {
    interface Window {
        buyEggGetAssetBalance: any;
        timeStart: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

dayjs.extend(LocalizedFormat);
dayjs.extend(duration);

axios.interceptors.request.use((config) => ({
    ...config,
    params: config.params ? { ...config.params, ...AuthDataSignService.authParams } : AuthDataSignService.authParams,
}));

const root = createRoot(document.getElementById('app'));

root.render(
    <AppProvider reduxStore={store} queryClient={queryClient}>
        <App />
    </AppProvider>,
);
