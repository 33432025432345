const XmasSnowRain = (): JSX.Element => {
    const snowflakesCount = 50;

    const generateSnowflakes = () =>
        Array.from({ length: snowflakesCount }).map((_, index) => {
            const left = (index / (snowflakesCount - 1)) * 90;
            const animationDelay = Math.random() * 50;
            const innerAnimationDelay = Math.random() * 50;
            const snowflakeHeight = 8 + Math.random() * 20;

            return (
                <div
                    className="snowflake"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{ left: `${left}%`, animationDelay: `${animationDelay}s` }}
                >
                    <div
                        className="inner"
                        style={{ animationDelay: `${innerAnimationDelay}s`, fontSize: `${snowflakeHeight}px` }}
                    >
                        ❅
                    </div>
                </div>
            );
        });

    return (
        <div className="snowflakes-container" style={{ height: '100%', width: '100%' }}>
            {generateSnowflakes()}
        </div>
    );
};

export default XmasSnowRain;
