const AnimalCoverContent = ({
    image,
    title,
    animal,
}: {
    animal: string;
    image?: string;
    title?: string;
}): JSX.Element => <img src={image} alt={`${title} ${animal}`} />;

export default AnimalCoverContent;
