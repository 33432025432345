import { ReactNode, SyntheticEvent } from 'react';
import { TFunction } from 'react-i18next';

import { AnimalCalls, AnimalType, IAnimalDetailsV2 } from '$shared/types/animals';

import { checkTooLittleToClaim } from '$/utils/ducks';

interface IGetButtonText {
    animalType?: AnimalType;
    onSale?: boolean;
    onFarming?: boolean;
    claim: number;
    buttonText?: ReactNode;
    t: TFunction;
}

export const getButtonText = (data: IGetButtonText): ReactNode => {
    const { animalType = AnimalType.DUCKS, onSale, onFarming, claim, buttonText = '', t } = data;

    switch (true) {
        case Boolean(buttonText): {
            return buttonText;
        }

        case onSale: {
            return t('portfolio.cards.on_sale');
        }

        case onFarming: {
            if (checkTooLittleToClaim(claim)) {
                return t('portfolio.cards.on_farming');
            }

            return t('portfolio.cards.claim', { claim: claim.toFixed(4), asset: AnimalCalls[animalType].farmingAsset });
        }

        default: {
            return t('portfolio.cards.stake');
        }
    }
};

interface IGetButtonDisabled {
    disabled?: boolean;
    onFarming?: boolean;
    withoutLink?: boolean;
    toClaim?: number;
    onClick?: (...args: IAnimalDetailsV2[]) => void;
}

export const getButtonDisabled = (data: IGetButtonDisabled): boolean => {
    const { disabled, withoutLink, toClaim, onFarming, onClick } = data;

    if (disabled || (withoutLink && !onClick)) {
        return true;
    }

    if (toClaim) {
        return Boolean(onFarming && parseFloat((Math.floor(toClaim) / 100).toFixed(4)) <= 0);
    }

    return false;
};

interface IGetOnClick {
    onFarming?: boolean;
    claim: number;
    onClick?: (event: SyntheticEvent) => void;
}

export const getOnClick = (data: IGetOnClick): ((event: SyntheticEvent) => void) | undefined => {
    const { onFarming, claim, onClick } = data;

    if (onFarming) {
        return checkTooLittleToClaim(claim) ? undefined : onClick;
    }

    return onClick;
};
