// eslint-disable-next-line import/prefer-default-export
export const isWebpSupported = (): void => {
    const t = document.createElement('canvas');
    try {
        if (t.toDataURL('image/webp').indexOf('data:image/webp') !== 0) {
            return;
        }

        document.body.classList.add('webp-supported');
    } catch (e) {}
};
