import type { Middleware } from 'redux';

import type { RootState } from '$/store';

import { CHANGE_SETTING, TOGGLE_SETTING } from '../actions/actions';

// Helpers to change class attribute
const updateElementClass = (el, stat, name) => el && el.classList[stat ? 'add' : 'remove'](name);
const updateBodyClass = (stat, name) => updateElementClass(document.body, stat, name);

/**
 When a setting value is changed, detect its value and add/remove
 a classname related with that setting from the target element.
 Export this method to talk directly with the middleware
 */
export const updateClasses = (state: RootState): void => {
    updateBodyClass(state.settings.isFixed, 'layout-fixed');
    updateBodyClass(state.settings.isBoxed, 'layout-boxed');
    // layout horizontal
    updateBodyClass(state.settings.horizontal, 'layout-h');
};

/*
    Hook into setting changes in order to change layout.
*/
const settings: Middleware = (store) => (next) => (action) => {
    const result = next(action);
    if (action.type === TOGGLE_SETTING || action.type === CHANGE_SETTING) {
        updateClasses(store.getState());
    }
    return result;
};

export default settings;
