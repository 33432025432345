const KEY = 'waves-ducks:public-key';

export const setPublicKey = (publicKey: string): string => {
    localStorage.setItem(KEY, publicKey);

    return publicKey;
};

export const getPublicKey = (): string | null => localStorage.getItem(KEY);

export const removePublicKey = (): void => localStorage.removeItem(KEY);
