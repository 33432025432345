import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { AnimalType } from '$shared/types/animals';
import { singularize } from '$shared/utils';

import ROUTES from '$/constants/routes';

import { Card } from '../Card';
import gates from './img/rebirth_gate.svg';
import st from './RebirthGates.module.scss';

interface IProps {
    assetId: string;
    animalType: AnimalType;
}

const RebirthGates: FC<IProps> = ({ assetId, animalType }) => {
    const { t } = useTranslation();
    const animalTranslation = `${animalType.toLocaleLowerCase()}s`;
    let path;
    switch (true) {
        case animalType === AnimalType.DUCKS:
            path = ROUTES.REBIRTH;
            break;
        case animalType === AnimalType.TURTLES:
            path = ROUTES.REBIRTH_TURTLE;
            break;
        case animalType === AnimalType.CANINES:
            path = ROUTES.REBIRTH_CANINE;
            break;
        case animalType === AnimalType.FELINES:
            path = ROUTES.REBIRTH_FELINE;
            break;
    }

    return (
        <Card
            cover={<img src={gates} alt={t('rebirth.gates')} />}
            coverClassName={st.imageContainer}
            to={generatePath(path, { nftId: assetId })}
            footer={{
                text: t('rebirth.progress', { animalType: singularize(t(`animals.${animalTranslation}`)) }),
                className: st.button,
            }}
            background="#363B46"
        />
    );
};

export default RebirthGates;
