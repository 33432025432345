import { useMemo } from 'react';

import { IAnimalsParamsFromGenotype } from '$shared/types/animals';

import turtlesService from '$/services/api/turtles';

interface IUseTurtleParams {
    assetId: string;
    name: string;
}

const useTurtleParams = (
    details: IUseTurtleParams | undefined | null,
    options: { cacheTs?: number } = {},
): IAnimalsParamsFromGenotype | undefined => {
    const { assetId, name } = details ?? {};
    const { cacheTs } = options;

    return useMemo(() => {
        if (name) {
            return turtlesService.getAnimalParametersFromGenotype(name, { assetId, cacheTs });
        }
    }, [assetId, name, cacheTs]);
};

export default useTurtleParams;
