import axios from 'axios';

import { API_URL } from '$/constants';

import configsMap from './configsMap';

const fetchConfigs = async (): Promise<void> => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { data } = await axios.get<Record<string, any>>(`${API_URL}/v1/configs`);

        Object.entries(data).forEach(([key, item]) => {
            configsMap.set(key, item);
        });
    } catch (e) {
        console.error('[ERROR][CONFIGS FETCH]', e);
    }
};

export default fetchConfigs;
