const BANNED_SENTENCES = [
    '>>>>> REQUESTING ADVICE',
    '>>>>> SENDING CARD TO SERVER',
    '---------------------------------------------',
    'Jedi window appeared',
    'stopjedi',
    'УНИВЕРСАЛЬНОЕ',
    'НЕТ',
    'Мои карты',
    'Соперник карты',
    'Оптимальная карта',
    'Мой ХП',
    'Сопрник ХП',
    'Карта с максимальной защитой',
    'Гавно-карта',
    'Переключаемся на альтернативу',
    'Размен нежелателен',
    'CAtch Result class',
];

const consoleInterceptors: { [T in keyof typeof console]?: Array<(typeof console)[T]> } = {
    log: [
        (...args): void => {
            const shouldBan = args.some((arg) => {
                const strArg = arg?.toString();

                return BANNED_SENTENCES.some((bannedSentence) => strArg?.includes(bannedSentence));
            });

            if (shouldBan) {
                window.location.reload();
            }
        },
    ],
};

export default consoleInterceptors;
