import { EGG_DECIMALS } from '$shared/constants';
import { Worlds } from '$shared/enums';

export const getSellAssetName = (eggSell: boolean | undefined): 'EGG' | 'WAVES' => (eggSell ? 'EGG' : 'WAVES');

export const getGenes = (name = ''): string => name.split('-')[1];

export const checkDuckHunt = (world = Worlds.None): boolean => world === Worlds.Hunt;

export const checkTooLittleToClaim = (amount: number): boolean => amount < 0.0001;

export const getToClaim = (toClaim = 0): number => Math.floor(toClaim) / 100;

export const getPrice = (price?: number | integer, eggSell?: boolean, toFixed = 3, rounding = false): string => {
    if (!price) {
        return '';
    }

    const value = price / EGG_DECIMALS;

    if (rounding && value >= 1000) {
        return `${(value / 1000).toFixed(1)}K ${getSellAssetName(eggSell)}`;
    }

    return `${value.toFixed(toFixed)} ${getSellAssetName(eggSell)}`;
};
