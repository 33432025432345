import type { AnyAction } from 'redux';

export const WX_EMAIL_LOGIN = 'WX_EMAIL_LOGIN';
export const MM_LOGIN = 'MM_LOGIN';
export const WX_WEB_LOGIN = 'WX_CLOUD_LOGIN';
export const KEEPER_LOGIN = 'KEEPER_LOGIN';
export const MOBILE_KEEPER_LOGIN = 'MOBILE_KEEPER_LOGIN';
export const FINISH_LOGIN = 'FINISH_LOGIN';
export const UPDATE_BALANCES = 'UPDATE_BALANCES';
export const CONNECT_SOCIAL_PROVIDER = 'CONNECT_SOCIAL_PROVIDER';
export const CHANGE_USER_ADDRESS = 'CHANGE_USER_ADDRESS';
export const CHANGE_USER_PUBLIC_KEY = 'CHANGE_USER_PUBLIC_KEY';

export const LOGOUT = 'LOGOUT';

export const startWxEmailLogin = (): AnyAction => ({ type: WX_EMAIL_LOGIN });

export const startWxWebLogin = (): AnyAction => ({ type: WX_WEB_LOGIN });

export const startKeeperLogin = (): AnyAction => ({ type: KEEPER_LOGIN });

export const startMMLogin = (): AnyAction => ({ type: MM_LOGIN });

export const startMobileKeeperLogin = (): AnyAction => ({ type: MOBILE_KEEPER_LOGIN });

export const startLogout = (authType: string): AnyAction => ({ type: LOGOUT, authType });

export const updateBalances = (address: string): AnyAction => ({ type: UPDATE_BALANCES, address });

export const changeUserAddress = (address: string): AnyAction => ({ type: CHANGE_USER_ADDRESS, address });

export const changeUserPublicKey = (publicKey: string): AnyAction => ({ type: CHANGE_USER_PUBLIC_KEY, publicKey });
