import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ACHIEVEMENT_TYPES } from '$shared/constants';
import { AnimalType } from '$shared/types/animals';
import { singularize } from '$shared/utils';

import Tooltip from '$/components/common/Tooltip';

import st from './Achievements.module.scss';

interface IProps {
    className?: string;
    itemClassName?: string;
    items?: string[];
    animalType: AnimalType;
}

const Achievements: FC<IProps> = ({ className, items = [], itemClassName, animalType }) => {
    const { t } = useTranslation();

    if (!items.length) {
        return null;
    }

    const achievements = items.map((achievement) => (
        <Tooltip
            key={achievement}
            theme={ACHIEVEMENT_TYPES[achievement].color}
            position="top"
            target={
                <img
                    alt={achievement}
                    className={itemClassName}
                    id={achievement}
                    src={`/ducks/achievements/${achievement}.png`}
                />
            }
        >
            <b>{t(`achievement.${achievement}`)}</b>
            <br />
            {t(`achievement.${achievement}_description`, {
                animalType: singularize(t(`animals.${animalType.toLowerCase()}s`)).toLowerCase(),
            })}
        </Tooltip>
    ));

    return <div className={cn(st.achievements, className)}>{achievements}</div>;
};

export default Achievements;
