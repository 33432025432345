import { useMemo } from 'react';

import type { AppConfigs } from '$shared/constants/configs';

import appConfigs from '$/services/api/app-configs';

function useAppConfig<K1 extends keyof AppConfigs>(key: K1): AppConfigs[K1];
function useAppConfig<K1 extends keyof AppConfigs, K2 extends keyof AppConfigs[K1]>(
    key1: K1,
    key2: K2,
): AppConfigs[K1][K2];
function useAppConfig<
    K1 extends keyof AppConfigs,
    K2 extends keyof AppConfigs[K1],
    K3 extends keyof AppConfigs[K1][K2],
>(key1: K1, key2: K2, key3: K3): AppConfigs[K1][K2][K3];
function useAppConfig<
    K1 extends keyof AppConfigs,
    K2 extends keyof AppConfigs[K1],
    K3 extends keyof AppConfigs[K1][K2],
    K4 extends keyof AppConfigs[K1][K2][K3],
>(key1: K1, key2: K2, key3: K3, key4: K4): AppConfigs[K1][K2][K3][K4];
function useAppConfig<Keys extends [keyof AppConfigs, ...string[]]>(...keys: Keys): Get<Keys, AppConfigs> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return useMemo(() => appConfigs.getConfig(...keys), [keys]);
}

export default useAppConfig;
