import { getAddress } from '$shared/domain/constants';
import CommonAnimalsFarmingService from '$shared/services/animals-farming';

import helperService from '../helper';

const CANINES_FARMING_DAPP_ADDRESS = getAddress('CANINES_FARMING_DAPP');

class FrontendCaninesBreedingService extends CommonAnimalsFarmingService {
    protected DAPP_ADDRESS = CANINES_FARMING_DAPP_ADDRESS;

    FARMING_ITEM_NAME = 'perches';

    protected helperService = helperService;
}

export default FrontendCaninesBreedingService;
