import { getAddress } from '$shared/domain/constants';
import { ArtefactType } from '$shared/types';

import artefactService from '$/services/api/artefact';

const DUCK_HOUSE_DAPP_ADDRESS = getAddress('DUCK_HOUSE_DAPP');
const MEGA_DUCK_HOUSE_DAPP_ADDRESS = getAddress('MEGA_DUCK_HOUSE_DAPP');
const XMAS_STBLE_DAPP_ADDRESS = getAddress('XMAS_STBLE_DAPP');

const typeHouse = (type: ArtefactType) => {
    if (artefactService.isXmasStble(type)) {
        return XMAS_STBLE_DAPP_ADDRESS;
    }

    return artefactService.isMegaDuckHouse(type) ? MEGA_DUCK_HOUSE_DAPP_ADDRESS : DUCK_HOUSE_DAPP_ADDRESS;
};

export default typeHouse;
