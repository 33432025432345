import CommonScanService from '$shared/services/scan';
import type { AssetDetails, DuckAchievements } from '$shared/types';
import { MutantAchievements } from '$shared/types/scan';
import { chunkUpBy, urlString } from '$shared/utils';

import { SCAN_URL } from '$/constants';

import helperService from '../helper';

class FrontendScanService extends CommonScanService {
    SCAN_URL = SCAN_URL;

    helperService = helperService;

    fetchAchievementsForDucks = async <T extends { assetId: string }>(
        ducks: T[],
    ): Promise<Record<duckId, DuckAchievements>> => {
        const batchedNfts = chunkUpBy(50, ducks);

        const results = await Promise.all(
            batchedNfts.map((batch) => {
                const idsWithSeparator = batch.map((nft) => nft.assetId).join(',');

                return this.helperService.http.get<Record<duckId, DuckAchievements>>(
                    urlString(`${this.SCAN_URL}/achievements`, { ids: idsWithSeparator }),
                );
            }),
        );

        return results.reduce(
            (res: Record<duckId, DuckAchievements>, { data }) => ({
                ...res,
                ...data,
            }),
            {},
        );
    };

    fetchAchievementsForTurtles = async <T extends { assetId: string }>(
        turtles: T[],
    ): Promise<Record<duckId, DuckAchievements>> => {
        const batchedNfts = chunkUpBy(50, turtles);

        const results = await Promise.all(
            batchedNfts.map((batch) => {
                const idsWithSeparator = batch.map((nft) => nft.assetId).join(',');

                return this.helperService.http.get<Record<duckId, DuckAchievements>>(
                    urlString(`${this.SCAN_URL}/trtl/achievements`, { ids: idsWithSeparator }),
                );
            }),
        );

        return results.reduce(
            (res: Record<duckId, DuckAchievements>, { data }) => ({
                ...res,
                ...data,
            }),
            {},
        );
    };

    fetchAchievementsForMutants = async <T extends { assetDetails: AssetDetails }>(
        mutants: T[],
    ): Promise<Record<string, MutantAchievements>> => {
        const batchedNfts = chunkUpBy(50, mutants);

        const results = await Promise.all(
            batchedNfts.map((batch) => {
                const idsWithSeparator = batch.map((nft) => nft.assetDetails.assetId).join(',');

                return this.helperService.http.get<Record<string, MutantAchievements>>(
                    urlString(`${this.SCAN_URL}/mtnt/achievements`, { ids: idsWithSeparator }),
                );
            }),
        );

        return results.reduce(
            (res: Record<string, MutantAchievements>, { data }) => ({
                ...res,
                ...data,
            }),
            {},
        );
    };
}

export default FrontendScanService;
