import { Reducer } from 'react';

import {
    CHANGE_USER_ADDRESS,
    CHANGE_USER_PUBLIC_KEY,
    CONNECT_SOCIAL_PROVIDER,
    FINISH_LOGIN,
    LOGOUT,
    UPDATE_BALANCES,
} from '../actions/actions';

const initialState = {
    path: 'themes/theme-e.css',
    connectedProviders: {},
};

// eslint-disable-next-line default-param-last, @typescript-eslint/no-explicit-any
const authReducer: Reducer<any, any> = (state = initialState, action) => {
    switch (action.type) {
        case FINISH_LOGIN: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case LOGOUT:
            return {
                ...state,
                address: null,
                publicKey: null,
                authType: null,
            };
        case UPDATE_BALANCES:
            return {
                ...state,
                ...action.payload,
            };
        case CONNECT_SOCIAL_PROVIDER: {
            const connectedProviders = state.connectedProviders || {};
            return {
                ...state,
                connectedProviders: {
                    ...connectedProviders,
                    [action.providerKey]: action.data,
                },
            };
        }
        case CHANGE_USER_ADDRESS: {
            return {
                ...state,
                address: action.address,
            };
        }
        case CHANGE_USER_PUBLIC_KEY: {
            return {
                ...state,
                publicKey: action.publicKey,
            };
        }
        default:
            return state;
    }
};

export default authReducer;
