import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import type { IDuckDetails, WithHuntParams } from '$shared/types';
import { WithRentalParams } from '$shared/types/hunt';

import ducksService from '$/services/api/ducks';
import { isAssetId } from '$/utils/assets';

const duckDetailsQueryKey = (nftId: duckId): string[] => ['duck-details-without-extra', nftId];
/** @deprecated */
const useDuckDetailsWithoutExtra = (nftId: string): UseQueryResult<IDuckDetails & WithHuntParams & WithRentalParams> =>
    useQuery(duckDetailsQueryKey(nftId), () => ducksService.fetchOneDuckDetailsWithoutExtra(nftId), {
        enabled: isAssetId(nftId),
        staleTime: 10 * ONE_SECOND,
    });

export default useDuckDetailsWithoutExtra;
