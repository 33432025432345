import { TELEGRAM_LINK, TWITTER_LINK } from '$shared/constants';

import HiddenBeautyView from '$/components/HiddenBeautyView';

const UnderConstructionPage = (): JSX.Element => (
    <HiddenBeautyView>
        <p>Under construction</p>
        <p>Please keep in touch with us for further updates</p>
        <p>
            <a href={TELEGRAM_LINK}>Telegram</a> | <a href={TWITTER_LINK}>Twitter</a>&nbsp;&nbsp;&nbsp;
        </p>
    </HiddenBeautyView>
);

export default UnderConstructionPage;
