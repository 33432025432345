export const ACHIEVEMENT_TYPES = {
    'pioneer': { color: 'silver' },
    'lasttrain': { color: 'silver' },
    '1000waves': { color: 'silver' },
    'total100': { color: 'silver' },
    'millesimal': { color: 'golden' },
    'color100': { color: 'silver' },
    'multigen': { color: 'golden' },
    'destroyer': { color: 'golden' },
    'onestep': { color: 'golden' },
    'guard': { color: 'golden' },
    'xmasrage': { color: 'golden' },
    'valentine': { color: 'golden' },
    'hween23': { color: 'golden' },
    'hween24': { color: 'golden' },
    'xmas23': { color: 'golden' },
    'first': { color: 'platinum' },
    'genotype1': { color: 'platinum' },
    'generation1': { color: 'platinum' },
    'grownup': { color: 'platinum' },
    'easter24': { color: 'golden' },
};
