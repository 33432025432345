import { useQueryClient } from 'react-query';

import useEffectiveCallback from './useEffectiveCallback';

const useInvalidateQueryCache = (
    ...keys: Array<unknown | unknown[]>
): ((...overrideKeys: Array<unknown | unknown[]>) => Promise<void[]>) => {
    const queryClient = useQueryClient();

    return useEffectiveCallback((...overrideKeys: Array<unknown | unknown[]>) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Promise.all([...keys, ...overrideKeys].map((key) => queryClient.invalidateQueries(key as any))),
    );
};

export default useInvalidateQueryCache;
