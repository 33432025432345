import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DUCK_COLORS } from '$shared/constants';

import Title from '$/components/common/Title';
import TooltipItem from '$/components/old-pages/Portfolio/TooltipItem';
import UpdateLevelModal from '$/components/old-pages/Portfolio/components/UpdateLevelModal';
import useAuth from '$/hooks/useAuth';

import st from '../Artefact/Artefact.module.scss';
import { Card } from '../Card';

interface IProps {
    artefact: {
        id: string;
        level: number;
    };
    num: number;
    onMantleUpgraded: () => void;
    onClick?: () => void;
}

const FreeMantle: FC<IProps> = ({ artefact, onMantleUpgraded, onClick, num }) => {
    const { t } = useTranslation();

    const { authType, userAddress } = useAuth();
    const { id, level } = artefact;

    const getLevel = () => {
        if (artefact.hasOwnProperty('level')) {
            return (
                <TooltipItem
                    content={t('common.mantle_tooltip', { lvl: level })}
                    id={`mantle_lvl_${id}`}
                    placement="top"
                >
                    <div className={cn('bg-gray duck-badge generation level-tag', st.badge)}>
                        {`${level} LVL `}
                        <i className="fas fa-arrow-alt-circle-up" />
                    </div>
                </TooltipItem>
            );
        }
    };

    const handleUpgradeMantle = () => {
        const upgradeLevelModal = new UpdateLevelModal({
            t,
            authType,
            currentLevel: level,
            artefactId: id,
            userAddress,
        });

        upgradeLevelModal.fire().then(() => {
            if (onMantleUpgraded) {
                onMantleUpgraded();
            }
        });
    };

    return (
        <Card
            background={`#${DUCK_COLORS.U}`}
            cover={<img src="/ducks/artefacts/mantle.svg" alt={t('mantle.free_mantle')} />}
            footer={{
                text: t('mantle.select_duck'),
                secondButton: {
                    text: t('mantle.upgrade'),
                    onClick: handleUpgradeMantle,
                },
            }}
            onClick={onClick}
        >
            <Title as="h3" size="h2" className={st.titleContainer}>
                {t('mantle.mantle')}
                {getLevel()}
                {num !== undefined && num > 1 && <p className={st.number}>{num}x</p>}
            </Title>

            <p className={st.text}>{t('mantle.text')}</p>
        </Card>
    );
};

export default FreeMantle;
