import CommonMutantsBreedingService from '$shared/services/mutants-breeding';

import { DUXPLORER_URL } from '$/constants';

import assetsService from '../assets';
import helperService from '../helper';
import transactionService from '../transaction';

class FrontendMutantsBreedingService extends CommonMutantsBreedingService {
    DUXPLORER_URL = DUXPLORER_URL;

    helperService = helperService;

    transactionService = transactionService;

    assetsService = assetsService;
}

export default FrontendMutantsBreedingService;
