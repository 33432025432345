import cn from 'classnames';
import { FC } from 'react';

import Button from '$/components/common/Button';
import { TDuckCardFooter } from '$/types/duck';

import st from './CardFooter.module.scss';

const CardFooter: FC<TDuckCardFooter> = ({
    isAuth,
    disabled,
    hidden,
    buttonSize: size,
    component,
    className,
    buttonClassName,
    secondButton,
    bottomButton,
    text,
    to,
    dropdown,
    onClick,
    isMutant,
}) => {
    if (hidden) {
        return null;
    }

    if (component) {
        return component;
    }

    const asLink = !onClick && to;

    const { text: secondButtonText, to: secondButtonTo, onClick: onSecondButtonClick } = secondButton || {};
    const { text: bottomButtonText, to: bottomButtonTo, onClick: onBottomButtonClick } = bottomButton || {};

    const btnProps = { fluid: true, disabled: !isAuth || disabled, size };
    const isMutantColor = isMutant ? st.mutantColorText : st.btn;
    return (
        <div className={cn(st.footer, className)}>
            {dropdown}
            <Button
                {...btnProps}
                className={cn(st.btn, { [st.asLink]: asLink }, buttonClassName, isMutant)}
                {...(asLink && { href: to })}
                onClick={onClick}
            >
                {text}
            </Button>

            {secondButton && (
                <Button
                    {...btnProps}
                    className={cn(st.btn, { [st.asLink]: !onSecondButtonClick && secondButtonTo }, buttonClassName)}
                    {...(!onSecondButtonClick && secondButtonTo && { href: secondButtonTo })}
                    onClick={onSecondButtonClick}
                >
                    {secondButtonText}
                </Button>
            )}
            {bottomButton && (
                <Button
                    {...btnProps}
                    className={cn(
                        st.btn,
                        { [st.asLink]: !onBottomButtonClick && secondButtonTo, isMutantColor },
                        st.bottomButton,
                    )}
                    {...(!onBottomButtonClick && bottomButtonTo && { href: bottomButtonTo })}
                    onClick={onBottomButtonClick}
                >
                    {bottomButtonText}
                </Button>
            )}
        </div>
    );
};

export default CardFooter;
