/* eslint-disable @typescript-eslint/no-explicit-any */
import { allPass, anyPass, complement, memoizeWith, Pred } from 'ramda';

export const or = <F extends Pred>(...preds: readonly F[]): F => anyPass(preds);
export const and = <F extends Pred>(...preds: readonly F[]): F => allPass(preds);

export const exclude = <T>(pred: (item: T) => boolean, list: T[]): T[] => list.filter(complement(pred));

export const memoize = <Args extends readonly any[], R>(fn: (...args: Args) => R): ((...args: Args) => R) =>
    memoizeWith(
        (...args: any[]) => args.reduce((res, a) => `${JSON.stringify(res)}:$$:${JSON.stringify(a)}`),
        fn as any,
    );

export { memoizeWith, complement as not };

export {
    __,
    add,
    addIndex,
    adjust,
    all,
    always,
    andThen,
    any,
    ap,
    aperture,
    append,
    apply,
    applySpec,
    applyTo,
    ascend,
    assoc,
    assocPath,
    binary,
    bind,
    both,
    call,
    chain,
    clamp,
    clone,
    comparator,
    compose,
    composeWith,
    concat,
    cond,
    construct,
    constructN,
    converge,
    count,
    countBy,
    curry,
    curryN,
    dec,
    defaultTo,
    descend,
    difference,
    differenceWith,
    dissoc,
    dissocPath,
    divide,
    drop,
    dropLast,
    dropLastWhile,
    dropRepeats,
    dropRepeatsWith,
    dropWhile,
    either,
    empty,
    endsWith,
    eqBy,
    eqProps,
    equals,
    evolve,
    F,
    filter,
    find,
    findIndex,
    findLast,
    findLastIndex,
    flatten,
    flip,
    forEach,
    forEachObjIndexed,
    fromPairs,
    groupBy,
    groupWith,
    gt,
    gte,
    has,
    hasIn,
    hasPath,
    head,
    identical,
    identity,
    ifElse,
    inc,
    includes,
    indexBy,
    indexOf,
    init,
    innerJoin,
    insert,
    insertAll,
    intersection,
    intersperse,
    into,
    invert,
    invertObj,
    invoker,
    is,
    isEmpty,
    isNil,
    join,
    juxt,
    keys,
    keysIn,
    last,
    lastIndexOf,
    length,
    lens,
    lensIndex,
    lensPath,
    lensProp,
    lift,
    liftN,
    lt,
    lte,
    map,
    mapAccum,
    mapAccumRight,
    mapObjIndexed,
    match,
    mathMod,
    max,
    maxBy,
    mean,
    median,
    mergeAll,
    mergeDeepLeft,
    mergeDeepRight,
    mergeDeepWith,
    mergeDeepWithKey,
    mergeLeft,
    mergeRight,
    mergeWith,
    mergeWithKey,
    min,
    minBy,
    modulo,
    move,
    multiply,
    nAry,
    negate,
    none,
    nth,
    nthArg,
    o,
    objOf,
    of,
    omit,
    once,
    otherwise,
    over,
    pair,
    partial,
    partialRight,
    partition,
    path,
    pathEq,
    pathOr,
    paths,
    pathSatisfies,
    pick,
    pickAll,
    pickBy,
    pipe,
    pipeWith,
    pluck,
    prepend,
    product,
    project,
    prop,
    propEq,
    propIs,
    propOr,
    props,
    propSatisfies,
    range,
    reduce,
    reduceBy,
    reduced,
    reduceRight,
    reduceWhile,
    reject,
    remove,
    repeat,
    replace,
    reverse,
    scan,
    set,
    slice,
    sort,
    sortBy,
    sortWith,
    split,
    splitAt,
    splitEvery,
    splitWhen,
    startsWith,
    subtract,
    sum,
    symmetricDifference,
    symmetricDifferenceWith,
    T,
    tail,
    take,
    takeLast,
    takeLastWhile,
    takeWhile,
    tap,
    test,
    thunkify,
    times,
    toLower,
    toPairs,
    toPairsIn,
    toString,
    toUpper,
    transduce,
    transpose,
    traverse,
    trim,
    tryCatch,
    type,
    unapply,
    unary,
    uncurryN,
    unfold,
    union,
    unionWith,
    uniq,
    uniqBy,
    uniqWith,
    unless,
    unnest,
    until,
    update,
    useWith,
    values,
    valuesIn,
    view,
    when,
    where,
    whereEq,
    without,
    xor,
    xprod,
    zip,
    zipObj,
    zipWith,
} from 'ramda';
