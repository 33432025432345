import { type ReactNode } from 'react';
import { Provider } from 'react-redux';
import type { Store } from 'redux';

interface Props {
    store: Store;
    children: ReactNode;
}

const ReactReduxProvider = ({ store, children }: Props): JSX.Element => <Provider store={store}>{children}</Provider>;

export default ReactReduxProvider;
