import { ParsedQs, stringify } from 'qs';

import type { ROUTES } from '$/constants';

function getUrl(route: string, params?: ParsedQs): string;
/**
 * @deprecated For routes use generatePath instead
 */
function getUrl(route: (typeof ROUTES)[keyof typeof ROUTES], params: ParsedQs): string;
function getUrl(route: string, params: ParsedQs = {}): string {
    const { query } = params;
    let url = route;

    Object.entries(params).forEach(([key, value]) => {
        url = url.replace(`:${key}`, String(value));
    });

    if (query && Object.keys(query).length !== 0) {
        url = `${url}?${stringify(query, { arrayFormat: 'comma', skipNulls: true })}`;
    }

    return url;
}

export default getUrl;
