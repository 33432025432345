export enum QR_HUNT_ERROR_CODE {
    UNKNOWN = 'UNKNOWN',
    CODE_REDEEMED = 'CODE_REDEEMED',
    WRONG_CODE = 'WRONG_CODE',
    PHONE_NUMBER_APPLIED = 'PHONE_NUMBER_APPLIED',
    PHONE_NUMBER_NOT_VERIFIED = 'PHONE_NUMBER_NOT_VERIFIED',
    PRIZE_ALREADY_CLAIMED = 'PRIZE_ALREADY_CLAIMED',
    WRONG_PHONE_NUMBER_VERIFICATION = 'WRONG_PHONE_NUMBER_VERIFICATION',
    SEND_SMS_FAILURE = 'SEND_SMS_FAILURE',
    VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED',
}

export enum QR_HUNT_EVENT {
    PERMISSIONLESS = 'PERMISSIONLESS',
    CONSENSUS = 'CONSENSUS',
    NFT_NYC = 'NFT_NYC',
}

export enum QR_HUNT_WIN_TYPE {
    WWGSIRER = 'WWGSIRER',
    WWYAKVNK = 'WWYAKVNK',
    WWWWZHAO = 'WWWWZHAO',
    WWNAILWA = 'WWNAILWA',
    WWBUTERN = 'WWBUTERN',
    WWWWTECH = 'WWWWTECH',
    WWDUKSBY = 'WWDUKSBY',
    WWFIRGRL = 'WWFIRGRL',
    WWWDHEAT = 'WWWDHEAT',
    WWWWVIBE = 'WWWWVIBE',
    FERTILE = 'FERTILE',
    STERILE = 'STERILE',
    NONE = 'NONE',
}
