/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAddress } from '$shared/domain/constants';
import { isAddress } from '$shared/domain/guards';
import CommonCaninesService from '$shared/services/canines';
import { IAnimalDetails, IAnimalDetailsLocked, IAnimalDetailsV2 } from '$shared/types/animals';
import { urlString } from '$shared/utils';

import { API_URL, APP_URL, IMAGES_SERVICE_URL } from '$/constants';

import assetsService from '../assets';
import auctionService from '../auctions';
import caninesBreedingService from '../canines-breeding';
import caninesFarmingService from '../canines-farming/instance';
import caninesIncubatorService from '../canines-incubator';
import helperService from '../helper';
import productionService from '../production';
import scanService from '../scan';

class FrontendCaninesService extends CommonCaninesService {
    breedingService = caninesBreedingService;

    farmingService = caninesFarmingService;

    incubatorService = caninesIncubatorService;

    helperService = helperService;

    assetsService = assetsService;

    productionService = productionService;

    auctionService = auctionService;

    scanService = scanService;

    IMAGES_SERVICE_URL = IMAGES_SERVICE_URL;

    APP_URL = APP_URL;

    API_URL = API_URL;

    DAPPS_CONTRACTS = {
        farming: getAddress('CANINES_FARMING_DAPP'),
        auction: getAddress('AUCTION_DAPP'),
        rental: getAddress('RENT_DAPP_ADDRESS'),
    };

    fetchUserPageAnimal = async (address: addressId): Promise<IAnimalDetails[]> => {
        if (!isAddress(address)) {
            return [];
        }

        const { data } = await this.helperService.http.get<IAnimalDetails[]>(
            urlString(`${API_URL}/v1/address/${address}/canines`, {
                withStakedOn: ['none', 'farming', 'auction'],
                with: ['breedStatus', 'farmingStats', 'achievements'],
            }),
        );

        return data;
    };

    fetchOneAnimalDetailsV2 = async (nftId: string): Promise<IAnimalDetailsV2> => {
        const [canines] = await Promise.all([this.getOneAnimalDetailsV2(nftId, true)]);
        return canines;
    };

    fetchUserPageCanineV2 = async (address: addressId): Promise<IAnimalDetailsLocked[]> => {
        if (!isAddress(address)) {
            return [];
        }
        try {
            const { data } = await this.helperService.http.get(
                urlString(`${API_URL}/v2/addresses/${address}/canines/locked`),
                {
                    params: { size: 200_000 },
                },
            );

            const { data: result } = data;

            const allCaninesFromUser: IAnimalDetailsLocked[] = result.data.map((canine: IAnimalDetailsV2) => ({
                ...canine,

                onFarming: !canine?.locks
                    ? false
                    : canine.locks?.some(
                          (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.farming,
                      ),
                onSale: canine.locks?.some(
                    (lock: { assetId: string; dApp: string }) => lock.dApp === this.DAPPS_CONTRACTS.auction,
                ),
            }));

            return allCaninesFromUser;
        } catch (error) {
            console.error('[ERROR][FETCH USER PAGE TURTLES]', error);
            return [];
        }
    };
}

export default FrontendCaninesService;
