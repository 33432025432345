import { ReactNode } from 'react';

import pic from '$/assets/img/hidden-beauty.jpeg';

import classes from './HiddenBeautyView.module.css';

interface Props {
    children: ReactNode | ReactNode[];
}

const HiddenBeautyView = ({ children }: Props): JSX.Element => (
    <div className={classes.overlay}>
        <div className={classes.wrapper}>
            <img className={classes.img} src={pic} alt="hidden beauty" />
            {children}
        </div>
    </div>
);

export default HiddenBeautyView;
