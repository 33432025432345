import CommonUrlRecord from '$shared/structures/CommonUrlRecord';
import { isDevEnv, isDevOnlyEnv } from '$shared/utils';

class FrontendUrlRecord extends CommonUrlRecord {
    get DOMAIN_HOST() {
        return process.env.DOMAIN_HOST || window.location.hostname;
    }

    get APP_URL() {
        return (
            process.env.APP_URL || (isDevOnlyEnv() ? `http://${this.DOMAIN_HOST}:3000` : `https://${this.DOMAIN_HOST}`)
        );
    }

    get HUNT_SERVICE_HOST() {
        return process.env.HUNT_SERVICE_HOST || `${this.DOMAIN_HOST}:9001`;
    }

    get HUNT_WEB_SOCKET_URL() {
        return process.env.HUNT_WEB_SOCKET_URL || `ws://${this.HUNT_SERVICE_HOST}`;
    }

    get HUNT_SERVICE_URL() {
        return process.env.HUNT_SERVICE_URL || `http://${this.HUNT_SERVICE_HOST}`;
    }

    get API_URL() {
        return process.env.API_URL || (isDevOnlyEnv() ? `http://${this.DOMAIN_HOST}:8000/api` : `${this.APP_URL}/api`);
    }

    get SCAN_URL() {
        return process.env.SCAN_URL || (isDevOnlyEnv() ? `http://${this.DOMAIN_HOST}:5001` : `${this.API_URL}/v0`);
    }

    get BLOCKCHAIN_URL() {
        return process.env.BLOCKCHAIN_URL || `${this.API_URL}/blockchain`;
    }

    get IMAGES_SERVICE_URI() {
        return process.env.IMAGES_SERVICE_URI || this.IMAGES_SERVICE_URL;
    }

    get CACHE_READER_URL() {
        const STAGING_CACHE_READER_URL = 'https://cache.staging.wavesducks.com';
        return process.env.CACHE_READER_URL || (isDevEnv() ? `http://localhost:8005` : STAGING_CACHE_READER_URL);
    }
}

export const {
    APP_URL,
    DOMAIN_HOST,
    HUNT_WEB_SOCKET_URL,
    HUNT_SERVICE_URL,
    BACKEND_SWOPFI_URL,
    ROUTING_SWOPFI_URL,
    NODE_URL,
    DEPOSIT_WAVES_URL,
    DATA_SERVICE_URL,
    //SWOP_EXCHANGE_URL,
    API_URL,
    BLOCKCHAIN_URL,
    SCAN_URL,
    DUXPLORER_URL,
    RESERVE_DUXPLORER_URL,
    IMAGES_SERVICE_URL,
    STATICS_URL,
    CACHE_READER_URL,
} = new FrontendUrlRecord();
