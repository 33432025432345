import { ANIMAL_PROPERTIES } from '$shared/constants';
import { AnimalType } from '$shared/types/animals';

import { API_URL } from '$/constants';

// eslint-disable-next-line import/prefer-default-export
export const getPerchUrl = (color: string): string => `${API_URL}/v1/perches/${color}.svg`;
export const getVPerchUrl = (): string => `${API_URL}/v1/vperches/P.svg`;
export const getBeachUrl = (color: string): string => `${API_URL}/v1/beaches/${color}.svg`;
export const getDockingUrl = (color: string): string => `${API_URL}/v1/dockings/${color}.svg`;
export const getMutariumUrl = (color: string): string => `${API_URL}/v1/mutariums/${color}.svg`;

export const getStakingItemUrl = (color: string, animalType: AnimalType): string => {
    const stakingItem = ANIMAL_PROPERTIES.find((animal) => animal.animalType === animalType);
    return `${API_URL}/v1/${stakingItem?.stakingItem}/${color}.svg`;
};
