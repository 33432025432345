import axios from 'axios';

import { isAddress } from '$shared/domain/guards';
import type { UserAnnouncement } from '$shared/types';

import { API_URL } from '$/constants';

const getAll = async (
    address: addressId,
    params: { isNew?: boolean; isViewed?: boolean },
): Promise<UserAnnouncement[]> => {
    if (!isAddress(address)) {
        return [];
    }
    try {
        const { data } = await axios.get<UserAnnouncement[]>(`${API_URL}/v1/users/${address}/announcements`, {
            params,
        });

        return data ?? [];
    } catch (e) {
        return [];
    }
};

export default getAll;
