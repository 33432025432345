import axios from 'axios';

import type { FeatureID } from '$shared/constants';

import { API_URL } from '$/constants';

const getActiveFeatures = async (): Promise<string[]> => {
    try {
        const { data } = await axios.get<FeatureID[]>(`${API_URL}/v1/features`);

        return data;
    } catch (e) {
        console.error('Error while fetching features', e);
        return [];
    }
};

export default getActiveFeatures;
