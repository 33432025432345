import { ComponentProps, ReactElement, ReactNode, SyntheticEvent } from 'react';

import Button from '$/components/common/Button';

export enum ETurtleTabs {
    MAIN = 'main',
    FARMING = 'farming',
    TRADE = 'trade',
    REBIRTH = 'rebirth',
    SEND = 'send',
    MAKE_A_BID = 'make-a-bid',
}

export type TTurtleCardFooter = {
    isAuth?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    buttonClassName?: string;
    buttonSize?: ComponentProps<typeof Button>['size'];
    className?: string;
    secondButton?: {
        disabled?: boolean;
        text: string | ReactNode;
        to?: string;
        onClick?: () => void;
    };
    bottomButton?: {
        disabled?: boolean;
        text: string | ReactNode;
        to?: string;
        onClick?: () => void;
    };
    component?: ReactElement;
    text?: ReactNode;
    to?: string;
    onClick?: (event: SyntheticEvent) => void;
};
