import cn from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import icoLogo from '$/assets/sprite/logo.svg';
import Icon from '$/components/common/Icon';
import ROUTES from '$/constants/routes';

import logoText from './img/logo-text.png';
import st from './Logo.module.scss';

interface IProps {
    isMinimalistic?: boolean;
    className?: string;
    to?: string;
    onClick?: () => void;
}

const Logo: FC<IProps> = ({ to = ROUTES.HOME, onClick, className, isMinimalistic }) => (
    <Link to={to} className={cn(st.logoContainer, className)} onClick={onClick}>
        <Icon size="large" item={icoLogo} />

        {!isMinimalistic && <img className={st.logoText} src={logoText} alt="Arkimals" />}
    </Link>
);

export default Logo;
