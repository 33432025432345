import artefactService from './api/artefact';
import breedingService from './api/breeding';
import collectiveFarmsService from './api/collective-farm';
import ducksService from './api/ducks';
import helperService from './api/helper';
import pricesBalanceService from './api/prices-and-balance';
import transactionService from './api/transaction';
import userPageService from './api/user-page';

/**
 * @deprecated use concrete service import instead
 */
// eslint-disable-next-line import/prefer-default-export
export const apiService = {
    getWavesPrice: pricesBalanceService.getWavesPrice,
    getEggPrice: pricesBalanceService.getEggPrice,
    getBalances: pricesBalanceService.getBalances,
    getEggsBalance: pricesBalanceService.getEggsBalance,
    getAssetBalance: pricesBalanceService.getAssetBalance,
    getWavesBalance: pricesBalanceService.getWavesBalance,
    getPuzzleAssetPrices: pricesBalanceService.getPuzzleAssetPrices,
    getUserPageBalance: pricesBalanceService.getUserPageBalance,
    getAssetBalances: pricesBalanceService.getAssetBalances,
    getCollectiveFarms: collectiveFarmsService.getCollectiveFarms,
    assetIdAsFloat: helperService.assetIdAsFloat,
    fetchData: helperService.fetchData,
    getUserPageHistory: userPageService.getUserPageHistory,
    getMethodsForHistory: userPageService.getMethodsForHistory,
    getInvokesWithDucks: userPageService.getInvokesWithDucks,
    getMassTransfer: transactionService.getMassTransfer,
    getBreededDuck: breedingService.getBreededAnimal,
    getTransactionInfo: transactionService.getTransactionInfo,
    waitForTx: transactionService.waitForTx,
    getActiveBreedings: breedingService.getActiveBreedings,
    getBreedingFinishHeight: breedingService.getBreedingFinishHeight,
    getLootBoxesRemaining: artefactService.getLootBoxesRemaining,
    getTotalLiquidity: collectiveFarmsService.getTotalLiquidity,
    getArtefactTypeFromBox: artefactService.getArtefactTypeFromBox,
    getCollectiveFarmRewards: collectiveFarmsService.getCollectiveFarmRewards,
    plainText: helperService.plainText,
    numberFormatter: helperService.numberFormatter,
    convertMillisecondsToDuckTimeString: ducksService.convertMillisecondsToDuckTimeString,
    addCanBreed: breedingService.addCanBreed,
    getOneDuckDetails: ducksService.getOneDuckDetails,
    getNftOwner: helperService.getNftOwner,
    getBlockchainHeight: helperService.getBlockchainHeight,
    getNftsOnAddress: helperService.getNftsOnAddress,
    getCurrentWarsLap: ducksService.getCurrentWarsLap,
    getWarsLapByTs: ducksService.getWarsLapByTs,
    formatArtefactData: artefactService.formatArtefactData,
    getArtefactDetails: artefactService.getArtefactDetails,
    batchEntries: helperService.batchEntries,
    getArtefactImageUrl: artefactService.getArtefactImageUrl,
    getMonthlySalesUsd: pricesBalanceService.getMonthlySalesUsd,
    getPossiblePatterns: breedingService.getPossibleAnimals,
    getPossibleDucks: breedingService.getPossibleAnimalsChildrenNames,
    calculateGenotypeRarities: breedingService.calculateGenotypeRarities,
    calculateRarityOdds: breedingService.calculateRarityOdds,
};
