import cn from 'classnames';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '$/components/common/Button';
import Text from '$/components/common/Text';
import Title from '$/components/common/Title';
import { SHORT_DATE_MONTH, SHORT_DATE_MONTH_YEAR } from '$/constants';

import st from './NewsCard.module.scss';
import { useObserve } from './hooks';

type NewsLink =
    | {
          external: boolean;
          href: string;
          text: string;
          disabled?: boolean;
      }
    | {
          text: string;
          onClick?: () => void;
          disabled?: boolean;
      };

interface Props {
    id?: string;
    imgUrl: string;
    expireDateTime?: Date;
    releaseDateTime: Date;
    title: string;
    text: string;
    link: NewsLink;
    isColumn?: boolean;
    canObserve?: boolean;
    className?: string;
}

const NewsCard: FC<Props> = ({
    id,
    imgUrl,
    // TODO: check what to do with expireDateTime
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    expireDateTime,
    releaseDateTime,
    title,
    text,
    link,
    isColumn,
    canObserve,
    className,
    children,
}) => {
    const { t, i18n } = useTranslation();
    const ref = useObserve({ canObserve, id });

    const formattedDate = useMemo(() => {
        const isCurrentYear = dayjs().year() === dayjs(releaseDateTime).year();
        const dateFormat = isCurrentYear ? SHORT_DATE_MONTH : SHORT_DATE_MONTH_YEAR;

        return dayjs(releaseDateTime)
            .locale(i18n.language || 'en')
            .format(dateFormat);
    }, [releaseDateTime, i18n.language]);

    return (
        <article ref={ref} id={id} className={cn(st.card, { [st.isColumn]: isColumn }, className)}>
            <img className={st.img} src={imgUrl} alt={title} />

            <div className={st.content}>
                <div>
                    <time className={st.date} dateTime={releaseDateTime.toDateString()}>
                        {formattedDate}
                    </time>

                    <Title className={st.title} size="h2">
                        {t(title)}
                    </Title>

                    <Text as="p" className={st.text}>
                        {t(text)}
                    </Text>
                </div>

                {children}

                {'href' in link && (
                    <Button disabled={link.disabled} className={st.button} external={link.external} href={t(link.href)}>
                        {t(link.text)}
                    </Button>
                )}

                {'onClick' in link && (
                    <Button disabled={link.disabled} className={st.button} onClick={link.onClick}>
                        {t(link.text)}
                    </Button>
                )}
            </div>
        </article>
    );
};

export default NewsCard;
