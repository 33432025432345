import CommonTurtlesFarmingService from '$shared/services/turtles-farming';

import helperService from '../helper';

class FrontendTurtlesFarmingService extends CommonTurtlesFarmingService {
    protected helperService = helperService;
}

const farmingTurtlesService = new FrontendTurtlesFarmingService();

export default farmingTurtlesService;
