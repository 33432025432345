import axios from 'axios';

import type { UserAnnouncement } from '$shared/types';

import { API_URL } from '$/constants';

const set = async (
    address: addressId,
    announcementKey: string,
    isViewed: boolean,
    isNew?: boolean,
): Promise<Partial<UserAnnouncement>> => {
    try {
        const { data } = await axios.patch<UserAnnouncement>(
            `${API_URL}/v1/users/${address}/announcements/${announcementKey}`,
            { isNew, isViewed },
        );

        return data ?? {};
    } catch (e) {
        return {};
    }
};

export default set;
