import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';

import { isAddress } from '$shared/domain/guards';
import { RequireSignatureResult } from '$shared/types/auth';
import { int } from '$shared/utils';

import { AuthDataSignStore, authService } from '$/services/authentication';
import state from '$/state';
import { authAddressSelector, authPublicKeySelector, authTypeSelector } from '$/store/selectors';

import useCallLogin from './useCallLogin';
import useEffectiveCallback from './useEffectiveCallback';

interface Auth {
    userAddress: addressId;
    signature: string;
    authType: string;
    publicKey: string;
    needAuth: () => boolean;
    userSignature: string | null;
    requestSignature: () => Promise<{ signature?: string | null; timestamp?: integer | null }>;
    requireSignature: () => Promise<RequireSignatureResult>;
    isAuth: boolean;
}

const signatureState = {
    isRequesting: false,
    promise: Promise.resolve({ signature: '', timestamp: int(0) }),
};

const useAuth = (): Auth => {
    const userAddress = useSelector(authAddressSelector) ?? '';
    const authType = useSelector(authTypeSelector);
    const publicKey = useSelector(authPublicKeySelector);
    const callLogin = useCallLogin();
    const [userSignature, setUserSignature] = useRecoilState(state.auth.userSignatureAtom);
    const [userSignatureTimestamp, setUserSignatureTimestamp] = useRecoilState(state.auth.userSignatureTimestampAtom);

    const needAuth = useEffectiveCallback(() => {
        if (!isAddress(userAddress)) {
            callLogin();
            return true;
        }
        return false;
    });

    const requireSignature = useEffectiveCallback(async () => {
        if (signatureState.isRequesting) {
            return signatureState.promise;
        }
        signatureState.promise = new Promise((resolve, reject) => {
            if (userSignature !== null && userSignatureTimestamp !== null) {
                return resolve({
                    signature: userSignature,
                    timestamp: userSignatureTimestamp,
                });
            }

            signatureState.isRequesting = true;
            authService
                .signAuthData(authType, userAddress)
                .then((authData) => {
                    setUserSignature(authData.signature);
                    setUserSignatureTimestamp(authData.timestamp);
                    AuthDataSignStore.set({ publicKey, ...authData });

                    resolve({ signature: authData.signature, timestamp: authData.timestamp });
                })
                .catch(() => {
                    reject(new Error('This action requires user signature'));
                })
                .finally(() => {
                    signatureState.isRequesting = false;
                });
        });

        return signatureState.promise;
    });

    const requestSignature = useEffectiveCallback(async () => {
        try {
            return await requireSignature();
        } catch (e) {
            return {};
        }
    });

    return {
        userAddress,
        signature: 'unknown',
        needAuth,
        authType,
        publicKey,
        isAuth: isAddress(userAddress),
        userSignature,
        requestSignature,
        requireSignature,
    };
};

export default useAuth;
