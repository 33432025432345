import { generatePath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTES } from '$/constants';

import useEffectiveCallback from './useEffectiveCallback';

const useCallLogin = (): (() => void) => {
    const history = useHistory();
    const { pathname } = useLocation();

    return useEffectiveCallback(() => history.push(generatePath(ROUTES.LOGIN_WITH_RETURN, { returnUrl: pathname })));
};

export default useCallLogin;
