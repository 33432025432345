import cn from 'classnames';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import Swal from '$/components/Swal';
import Title from '$/components/common/Title';

import { Card } from '../Card';
import st from './HatchingCard.module.scss';

interface IProps {
    secondsRemaining: number;
    duckClaimedSwalOptions?;
    className?: string;
    imgClassName?: string;
}

const HatchingCard: FC<IProps> = ({ secondsRemaining, duckClaimedSwalOptions, className, imgClassName }) => (
    <>
        <Card
            className={cn(st.card, className)}
            coverClassName={cn(st.img, imgClassName)}
            cover={
                <Title as="span">
                    {Math.floor(secondsRemaining / 60)}:{String(secondsRemaining % 60).padStart(2, '0')}
                </Title>
            }
            footer={{
                disabled: true,
                text: <Trans i18nKey="incubator.claim.claim_btn_in_progress" />,
                className: st.footer,
                buttonClassName: st.btn,
            }}
        />

        {duckClaimedSwalOptions && <Swal options={duckClaimedSwalOptions} />}
    </>
);

export default HatchingCard;
