import { useQuery, UseQueryResult } from 'react-query';

import { ONE_SECOND } from '$shared/constants';
import { AnimalConnection, AnimalType } from '$shared/types/animals';

import ducksService from '$/services/api/ducks';
import turtlesService from '$/services/api/turtles';
import mutantsService from '$/services/api/mutants';
import caninesService from '$/services/api/canines';
import felinesService from '$/services/api/felines';

export const animalConnectionsQueryKey = (nftId: duckId, animalType: AnimalType): string[] => [
    `${animalType}-connections`,
    nftId,
];

const handleAnimalService = (animalType: AnimalType) => {
    switch (animalType) {
        case AnimalType.DUCKS:
            return ducksService;
        case AnimalType.TURTLES:
            return turtlesService;
        case AnimalType.MUTANTS:
            return mutantsService;
        case AnimalType.CANINES:
            return caninesService;
        case AnimalType.FELINES:
            return felinesService;
    }
};

const useAnimalConnections = (nftId: duckId, animalType: AnimalType): UseQueryResult<AnimalConnection[]> => {
    const animalService = handleAnimalService(animalType);

    return useQuery(animalConnectionsQueryKey(nftId, animalType), () => animalService.fetchAnimalConnections(nftId), {
        staleTime: 2 * ONE_SECOND,
    });
};

export default useAnimalConnections;
