// Sweet Alert
import swal from 'sweetalert';

/**
 * @deprecated
 * Wrapper component for sweetalert plugin
 */
const Swal = (props) => {
    const handleClick = (e) => {
        e.preventDefault();
        // pass swal reference so is possible to chain popups
        swal(props.options).then((p) => props.callback(p, swal));
    };

    const { callback, ...rest } = props;
    return (
        <button {...rest} type="button" onClick={handleClick}>
            {props.children}
        </button>
    );
};

Swal.defaultProps = {
    callback: () => {},
};

export default Swal;
