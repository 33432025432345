import type { Middleware } from 'redux';

import { GameplayEvents } from '$shared/enums';

import { ROUTES } from '$/constants';
import history from '$/services/history';
import GameApiService from '$/services/Wars/GameApiService';
import GameplayService from '$/services/Wars/GameplayService';
import { reachGoalOnce } from '$/utils/reachGoal';

import {
    CHANGE_FARM,
    cleanupGameplay,
    GAMEPLAY_INITIALIZED,
    INIT_GAMEPLAY,
    LOAD_FARMS,
    LOAD_GAME_DATA,
    LOAD_LEADERBOARD,
    LOAD_LEADERBOARD_SUCCESS,
    loadFarmsError,
    loadFarmsSuccess,
    loadGameDataError,
    loadGameDataSuccess,
    START_GAME,
    START_MATCHMAKING,
} from '../actions/wars.actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const warsMiddleware: Middleware = (store) => (next) => async (action: any) => {
    const result = next(action);
    const auth = store.getState().auth;
    const fetchAvailableGame = (silent = false) =>
        GameApiService.fetchAvailableGame().then(
            (payload) => store.dispatch(loadGameDataSuccess(payload, silent)),
            (error) => store.dispatch(loadGameDataError(error.response?.data || { error: 'fight.game_server_error' })),
        );

    const initiateCleanup = async () => {
        await GameplayService.leaveGame();
        // TODO: is this dispatch needed?
        store.dispatch(cleanupGameplay());
        history.push(ROUTES.FIGHT);
    };

    switch (action.type) {
        case LOAD_GAME_DATA:
            fetchAvailableGame(action.payload);
            break;
        case LOAD_FARMS:
            GameApiService.fetchFarms().then(
                (payload) => store.dispatch(loadFarmsSuccess(payload)),
                () => store.dispatch(loadFarmsError()),
            );
            break;
        case CHANGE_FARM:
            // eslint-disable-next-line no-underscore-dangle
            GameApiService.chooseFarm(store.getState().wars._id, action.payload.contract)
                .then(() => reachGoalOnce('CHOSE_FARM', auth.address, action.payload.contract))
                .then(() => store.dispatch(cleanupGameplay()))
                .then(() => fetchAvailableGame())
                .then(() => history.push(ROUTES.FIGHT));
            break;
        case LOAD_LEADERBOARD:
            // eslint-disable-next-line no-underscore-dangle
            GameApiService.fetchLeaderboardData(store.getState().wars._id).then((payload) =>
                store.dispatch({ type: LOAD_LEADERBOARD_SUCCESS, payload }),
            );
            break;
        case INIT_GAMEPLAY: {
            store.dispatch({ type: START_MATCHMAKING });
            const state = store.getState();
            // eslint-disable-next-line no-underscore-dangle
            await GameplayService.joinGame(state.wars.server, state.wars._id).then(async (patch) => {
                GameplayService.fightRoom.onMessage(GameplayEvents.START_GAME, () => {
                    // store.dispatch({ type: GAMEPLAY_STARTING });
                    store.dispatch({ type: START_GAME });
                });
                GameplayService.fightRoom.onMessage(GameplayEvents.SIGN_ERROR, async (message: string) => {
                    await initiateCleanup();
                    // eslint-disable-next-line no-alert
                    alert(message);
                });

                GameplayService.fightRoom.onMessage(GameplayEvents.LEFT_GAME_IN_PROGRESS, async (message: string) => {
                    await initiateCleanup();
                    // eslint-disable-next-line no-alert
                    alert(message);
                });

                window.history.replaceState('', document.title, `/promo/fight/gameplay/${patch.gameId}`);
                store.dispatch({ type: GAMEPLAY_INITIALIZED, payload: patch });
            });
            break;
        }
    }

    return result;
};

export default warsMiddleware;
