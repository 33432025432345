import axios from 'axios';

import { getAddress, getAssetID } from '$shared/domain/constants';
import { urlString } from '$shared/utils';

import { DATA_SERVICE_URL } from '$/constants';
import collectiveFarmService from '$/services/api/collective-farm';
import transactionService from '$/services/api/transaction';
import type { ITransactions } from '$/types';

import type { IUserPageHistory } from './types';

const EGG_ASSET_ID = getAssetID('EGG');
const OLD_EGG_ASSET_ID = getAssetID('OLD_EGG');

const AUCTION_DAPP_ADDRESS = getAddress('AUCTION_DAPP');
const BABY_DUCKS_DAPP_ADDRESS = getAddress('BABY_DUCKS_DAPP');
const DUCK_FARMING_DAPP_ADDRESS = getAddress('DUCK_FARMING_DAPP');
const GAME_DAPP_ADDRESS = getAddress('GAME_DAPP');
const DUCK_INCUBATOR_DAPP_ADDRESS = getAddress('DUCK_INCUBATOR_DAPP');
const LOOT_BOXES_DAPP_ADDRESS = getAddress('LOOT_BOXES_DAPP');
const DUCK_REBIRTH_DAPP_ADDRESS = getAddress('DUCK_REBIRTH_DAPP');
const SWOP_DAPP_ADDRESS = getAddress('SWOP_DAPP');

class UserPageService {
    getMethodsForHistory = (): { [key: string]: { [key2: string]: string } } => ({
        [DUCK_INCUBATOR_DAPP_ADDRESS]: {
            startDuckHatching: 'Duck hatching started',
        },
        [AUCTION_DAPP_ADDRESS]: {
            instantBuy: 'Duck buy', // может быть за EGG и Waves
        },
        [DUCK_FARMING_DAPP_ADDRESS]: {
            claimReward: 'Farming reward claimed', // приход
            unstakeNFT: 'Farming reward claimed', // приход
            buyPerch: 'Perch bought',
        },
        [DUCK_REBIRTH_DAPP_ADDRESS]: {
            initRebirth: 'Rebirth started',
        },
        [BABY_DUCKS_DAPP_ADDRESS]: {
            feedDuckling: 'Fed duckling',
        },
        [LOOT_BOXES_DAPP_ADDRESS]: {
            buyArtefact: 'Bought loot box',
        },
        [GAME_DAPP_ADDRESS]: {
            buyArtefact: 'Mantle buy',
            upgradeMantle: 'Mantle upgrade',
        },
        // [BUY_EGG_DAPP_ADDRESS]: {
        [SWOP_DAPP_ADDRESS]: {
            buyEgg: 'Buy EGG',
        },
        collectiveFarms: {
            provideLiquidity: ' liquidity providing',
        },
    });

    getUserPageHistory = async (address: string): Promise<IUserPageHistory[]> => {
        const collectiveFarms = await collectiveFarmService.getCollectiveFarms();
        const collectiveFarmsByContract = collectiveFarms.reduce((acc, farm) => {
            // eslint-disable-next-line no-param-reassign
            acc[farm.contract] = farm;
            return acc;
        }, {});

        const filteredTx = await this.getInvokesWithDucks(address, collectiveFarmsByContract);
        const methods = this.getMethodsForHistory();

        const transactionParams: Array<{
            id: string;
            title: string;
            amount: number;
            assetName: string;
            timestamp: string;
        }> = [];

        for (const tx of filteredTx) {
            const params: {
                title: string;
                amount: number;
                assetName: string;
                timestamp: string;
                id: string;
            } = {
                title: '',
                amount: 0,
                assetName: '',
                timestamp: '',
                id: tx.id,
            };
            let skipTx = false;
            if (tx.call['function'] === 'provideLiquidity') {
                params.title = collectiveFarmsByContract[tx.dApp].name + methods.collectiveFarms.provideLiquidity;
            } else {
                params.title = methods[tx.dApp][tx.call['function']];
            }
            let neededPayment;
            if (['claimReward', 'unstakeNFT', 'unstakeJackpot'].indexOf(tx.call['function']) !== -1) {
                params.timestamp = `${new Date(tx.timestamp)}`; // !!!!
                // eslint-disable-next-line no-await-in-loop
                const transactionDetails = await transactionService.getInvokeScriptTransactionInfo(tx.id);
                if (!transactionDetails) {
                    throw new Error('Something very wrong happened');
                }
                const transfers = transactionDetails.stateChanges.transfers.filter(
                    (transfer) =>
                        transfer.address === address &&
                        (transfer.asset === EGG_ASSET_ID || transfer.asset === OLD_EGG_ASSET_ID),
                );
                if (transfers.length > 0) {
                    neededPayment = {
                        amount: transfers[0].amount / 1e8,
                        assetId: transfers[0].asset,
                    };
                }
            } else {
                params.timestamp = tx.timestamp;
                neededPayment = tx.payment.filter(
                    (transfer) =>
                        transfer.assetId === EGG_ASSET_ID ||
                        transfer.assetId === OLD_EGG_ASSET_ID ||
                        transfer.assetId === null,
                )[0];
            }
            if (neededPayment) {
                params.amount = -neededPayment.amount;
                params.assetName =
                    neededPayment.assetId === EGG_ASSET_ID || neededPayment.assetId === OLD_EGG_ASSET_ID
                        ? 'EGG'
                        : 'WAVES';
            } else {
                skipTx = true;
            }
            if (!skipTx) {
                transactionParams.push(params);
            }
        }

        let result: IUserPageHistory[] = [];
        const farms = await collectiveFarmService.getCollectiveFarms();
        const massTransfers = await transactionService.getMassTransfer(address);
        const payments = await collectiveFarmService.getCollectiveFarmRewards(address);
        payments.forEach((payment) => {
            result.push({
                id: payment.id,
                amount: payment.amount / 1e8,
                token: 'EGG',
                operation: `Dividends from ${farms?.filter((f) => f.contract === payment.collectiveFarm)[0]?.title}`,
                date: new Date(payment.timestamp),
                img: '/ducks/egg-token.svg',
            });
        });
        massTransfers.forEach((mt) => {
            const assetId = mt.assetId;
            const farm = farms.find((f) => f.shareAssetId === assetId);
            if (farm) {
                result.push({
                    id: mt.id,
                    amount: mt.transfers.filter((t) => t.recipient === address)[0].amount,
                    token: farm.title,
                    operation: `Winning reward`,
                    date: new Date(mt.timestamp),
                    img: farm.cover,
                });
            }
        });
        transactionParams.forEach((tp) => {
            result.push({
                id: tp.id,
                amount: tp.amount,
                token: tp.assetName,
                operation: tp.title,
                date: new Date(tp.timestamp),
                img: tp.assetName === 'EGG' ? '/ducks/egg-token.svg' : '/currencies/waves.svg',
            });
        });
        result = result.sort((a, b) => (a.date.getTime() - b.date.getTime()) * -1);

        return result;
    };

    getInvokesWithDucks = async (
        address: string,
        collectiveFarmsByContract: { [key: string]: unknown },
    ): Promise<ITransactions[]> => {
        let after;
        let isLastPage = false;
        let allTxs: ITransactions[] = [];
        const methods = this.getMethodsForHistory();
        do {
            // eslint-disable-next-line no-await-in-loop
            const { data: txs } = await axios.get(
                urlString(`${DATA_SERVICE_URL}/transactions/invoke-script`, {
                    sender: address,
                    sort: 'desc',
                    limit: 100,
                    after,
                }),
                {
                    timeout: 10000,
                },
            );
            const filteredTx = txs.data
                .filter((tx) => {
                    const txData = tx.data;
                    return methods.hasOwnProperty(txData.dApp) || collectiveFarmsByContract.hasOwnProperty(txData.dApp);
                })
                .filter((tx) => {
                    const txData = tx.data;
                    return (
                        txData.call['function'] === 'provideLiquidity' ||
                        (methods.hasOwnProperty(txData.dApp) &&
                            methods[txData.dApp].hasOwnProperty(txData.call['function']))
                    );
                })
                .map((tx) => tx.data);
            allTxs = allTxs.concat(filteredTx);

            after = txs.lastCursor;
            isLastPage = txs.isLastPage || allTxs.length > 500;
        } while (!isLastPage);
        return allTxs;
    };
}

export default UserPageService;
