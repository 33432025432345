import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

interface Props {
    queryClient: QueryClient;
    children: ReactNode;
}

const ReactQueryProvider = ({ queryClient, children }: Props): JSX.Element => (
    <QueryClientProvider client={queryClient}>
        {children}

        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
);

export default ReactQueryProvider;
